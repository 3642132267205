import React, { useEffect } from "react";
import axios from "axios";
import Router from "./Router";
import { db, auth, analytics } from "./firebase";
import { setUserId, setUserDetails, setLoading, login } from "./features/appSlice";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectUserDetails, selectLoading } from "./features/appSlice";
import { setUserId as setAnalyticsUserId, setUserProperties } from "firebase/analytics";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";

function App() {
  const [user, loading] = useAuthState(auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const userDetails = useSelector(selectUserDetails);
  const userLoading = useSelector(selectLoading);

  //store user info into redux state
  useEffect(async () => {
    if (!loading && user) {
      //navigate('/dashboard');
      try {
        const userData = await getUserDoc({ uid: user.uid });

        dispatch(
          setUserId({
            userId: userData.id,
          })
        );

        dispatch(
          setUserDetails({
            userEmail: userData.userEmail,
            id: userData.id,
            role: userData.role,
            company: userData.company,
            firstName: userData.firstName,
            lastName: userData.lastName,
            clinicName: userData?.clinicName,
            province: userData?.province,
            license_number: userData?.license_number,
            favorites: userData?.favorites,
            testing: userData?.testing ? userData?.testing : false,
          })
        );

        dispatch(setLoading({ loading: false }));

        // Log userid
        setUserProperties(analytics, { app_user_id: user.uid });
        setAnalyticsUserId(analytics, user.uid);
      } catch (error) {}
    }
    // if no user and not loading user auth then dispatch setLoading false
    if (!loading && !user) {
      dispatch(setLoading({ loading: false }));
      setAnalyticsUserId(analytics, null);
    }
  }, [user, loading]);

  async function getUserDoc(user) {
    const token = await auth.currentUser.getIdToken();
    let axiosConfig = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    const data = axios.post(`${process.env.REACT_APP_API_URL}/auth/getUserDoc`, user, axiosConfig).then(
      (response) => {
        //console.log(response);
        if (response.status === 200) {
          return response.data;
        }
      },
      (error) => {
        console.log(error);
      }
    );
    return data;
  }

  if (loading || userLoading) {
    return (
      <div className="pageLoading">
        <Spinner animation="border" role="status"></Spinner>
      </div>
    );
  }
  return (
    <div>
      <Router user={user} userDetails={userDetails} />
    </div>
  );
}

export default App;
