import React, { useState, useEffect } from "react";
import { db } from "./../firebase";
import { doc, getDoc } from "firebase/firestore";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { default as MuiButton } from "@mui/material/Button";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./PatientLogin.css";

// Schema for yup
const validationSchema = Yup.object().shape({
  email: Yup.string().email("*Must be a valid email address").max(100, "*Email address must be less than 100 characters").required("*Email address is required"),

  presentationId: Yup.string().min(4, "*Pin code is not valid").max(60, "*Pin code is not valid").required("*Pin code is required"),
});

export const PatientLogin = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [presentationId, setPresentationId] = useState("");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (searchParams.get("presentationId")) {
      setPresentationId(searchParams.get("presentationId"));
    }
  }, []);
  const onSubmit = async (values) => {
    //e.preventDefault();
    const { ...data } = values;
    const presentationId = data.presentationId;
    const payload = {
      params: {
        presentationId: `presence-${presentationId}`,
        emailAddress: data.email || Math.random().toString(36).substr(2, 9),
      },
    };

    const docRef = doc(db, `requestAccess`, presentationId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const path = `/patient/presentation/${presentationId}`;
      navigate(path, {
        state: {
          presentationId: `presence-${presentationId}`,
          presentation: docSnap.data().presentation,
          emailAddress: data.email,
          presentationLanguage: docSnap.data().presentationLanguage,
        },
      });
      formik.resetForm();
      setError(null);
      setSuccess("true");
    } else {
      // doc.data() will be undefined in this case
      setSuccess(null);
      formik.setFieldError("presentationId", "Pin Code is not valid");
    }

    // axios.get(`${process.env.REACT_APP_API_URL}/requestPresentation`, payload)
    // .then((response) => {
    //   // handle success
    //   console.log('response', response.data);
    //   if(response.data.occupied) {
    //     const path = `/patient/presentation/${presentationId}`
    //     history.push({
    //       pathname: path,
    //       state: {
    //         presentationId: `presence-${presentationId}`,
    //         emailAddress: data.email
    //       }
    //     });
    //     formik.resetForm();
    //     setError(null);
    //     setSuccess(response.data.message);
    //   }
    //   else {
    //     setSuccess(null);
    //     formik.setFieldError('presentationId', 'Pin Code is not valid');
    //     //setError('Pin Code does not exist with that ID');
    //     console.log('!error')
    //   }

    // })
    // .catch((error) => {
    //   // handle error
    //   console.log(error);
    // })
    // .then(() => {
    //   console.log('executed')
    //   formik.setSubmitting(false)
    //   // always executed
    // });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      presentationId: presentationId,
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  return (
    <div id="patient_login_screen" className="patient_login_screen auth-wrapper" key={presentationId}>
      <div className="patient_login_screen__gydme-logo">
        <img alt="Gydme" src="/gydme-full-2-turq.svg" width="150" className="d-inline-block align-top" />
      </div>
      <Container className="patient_login_screen__formContainerPatient auth-inner">
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address:</Form.Label>
            <Form.Control type="email" placeholder="Enter your email address" name="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} className={formik.touched.email && formik.errors.email ? "error" : null} isValid={formik.touched.email && !formik.errors.email} isInvalid={formik.errors.email} />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
            <Form.Control.Feedback type="invalid">{formik.touched.email && formik.errors.email ? <div className="error-message">{formik.errors.email}</div> : null}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Pin code: </Form.Label>
            <Form.Control type="text" placeholder="Enter the presentation pin code" name="presentationId" value={formik.values.presentationId} onChange={formik.handleChange} onBlur={formik.handleBlur} className={formik.touched.presentationId && formik.errors.presentationId ? "error" : null} isValid={formik.touched.presentationId && !formik.errors.presentationId} isInvalid={formik.errors.presentationId} />
            <Form.Control.Feedback type="invalid">{formik.touched.presentationId && formik.errors.presentationId ? <div className="error-message">{formik.errors.presentationId}</div> : null}</Form.Control.Feedback>
          </Form.Group>
          <div className="patient_login_screen__patientFormError">
            <div className="error-message">{error}</div>
          </div>
          <Button type="submit" variant="dark w-100" disabled={formik.isSubmitting}>
            Join Presentation
          </Button>
        </Form>
        <div className="patient_login_footer d-flex justify-content-end mt-2">
          <MuiButton href="/login" className="login_link">
            Host a session
          </MuiButton>
          <MuiButton href="https://gyd.me/privacy-policy" target="_blank" className="login_link">
            Privacy Policy
          </MuiButton>
          <MuiButton href="https://gyd.me/terms-of-service/" target="_blank" className="login_link">
            Terms of Service
          </MuiButton>
        </div>
      </Container>
    </div>
  );
};

export default PatientLogin;
