import { auth } from "./../firebase";
import axios from "axios";

export async function sendPatientEmail(presentationName, presentationUsers, username, presentationLanguage, userDetails) {
  const token = await auth.currentUser.getIdToken();
  let axiosConfig = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
    Weblink = "",
    websiteLink = "",
    subjectLine = "";

  switch (presentationName) {
    case "RYBELSUS®":
      Weblink = "https://rybelsus.ca/deeplink-patient";
      websiteLink = "Rybelsus.ca";
      break;
    default:
      Weblink = "https://rybelsus.ca/deeplink-patient";
      websiteLink = "Rybelsus.ca";
  }

  //foreach presentationUsers
  let presentationUsersLength = presentationUsers.length;
  for (let i = 0; i < presentationUsersLength; i++) {
    //don't send it to the HCP
    if (auth.currentUser.email !== presentationUsers[i]) {
      const payload = {
        subject: `Your ${presentationName} Virtual Starter Kit`,
        presentationName: presentationName,
        patientEmail: presentationUsers[i],
        Weblink: Weblink,
        websiteLink: websiteLink,
        hcpEmail: auth.currentUser.email,
        presentationLanguage: presentationLanguage,
        hcpFirstName: userDetails.firstName,
        hcpLastName: userDetails.lastName,
      };

      axios.post(`${process.env.REACT_APP_API_URL}/sendEmail`, payload, axiosConfig).then(
        (response) => {
          // console.log(response.data.message);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
}
