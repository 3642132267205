import React, { useState, useEffect } from 'react'
import axios from 'axios';
import MainMenu from './../components/MainMenu';
import HcpFooter from './../components/HcpFooter';
import InviteHCP from './../components/Admin/InviteHCP'
import ListCompanyClinics from './../components/SalesRep/ListCompanyClinics'
import Container from 'react-bootstrap/Container'
import { db, auth } from "./../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { query, collection, where } from "firebase/firestore";
import "./admin.css";


export default function SalesRep(props) {
  const { user, userDetails } = props;
  const [companyCollectionUsers, setCompanyCollectionUsers] = useState([])

  const q = query(
    collection(db, "invitations"), 
    where("company", "==", props.userDetails.company),
    where("role", "==", 'Clinic')
  );
  const [companyInvitations, companyInvitationsLoading] = useCollectionData(q,  { idField: "id" });

  useEffect(() => {
    const fetchData = async () => {
      await getCompanyUsers();
    }
    fetchData();
  }, []);


  async function getCompanyUsers() {
    const userData = {
      company:userDetails.company,
      uid:userDetails.id,
    }
    const token = await auth.currentUser.getIdToken();
    let axiosConfig = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }

    const data = axios.post(`${process.env.REACT_APP_API_URL}/cac/getCompanyUsers`, userData, axiosConfig).then((response) => {
       //console.log(response);
      if(response.status === 200){
        setCompanyCollectionUsers(response.data);
        return response.data;
      }
    }, (error) => {
        console.log(error);
    });
    return data;
  }

  return (
    <div className="page-wrapper">
      <MainMenu username={user.email} userDetails={userDetails}/>
      <div className="admin_page">
        <Container style={{marginTop:'2rem'}}>
          <h1 className="dashboard__page_title">Invite a {props.userDetails.company} Clinic</h1>
          <p>Invite a new or existing gydme account to {props.userDetails.company}. </p>
          <InviteHCP getCompanyUsers={getCompanyUsers} company={userDetails.company} companyCollectionUsers={companyCollectionUsers} userDetails={props.userDetails} companyInvitations={companyInvitations} />
          <ListCompanyClinics getCompanyUsers={getCompanyUsers} companyCollectionUsers={companyCollectionUsers} userDetails={props.userDetails} companyInvitations={companyInvitations} />
        </Container>
      </div>
      <HcpFooter />
    </div>
  )
}
