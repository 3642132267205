import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import PatientLogin from "./pages/PatientLogin";
import Invite from "./pages/Invite";
import Dashboard from "./pages/Dashboard";
import Preferences from "./pages/Preferences";
import EditPresentation from "./pages/EditPresentation";
import HcpPresentation from "./pages/HcpPresentation";
import PatientPresentation from "./pages/PatientPresentation";
import NotFound from "./pages/NotFound";
import Admin from "./pages/Admin";
import SalesRep from "./pages/SalesRep";
import AdminRoutes from "./AdminRoutes";
import SalesRepRoutes from "./SalesRepRoutes";
import Pusher from "pusher-js";
import { useSelector } from "react-redux";
import { selectUserDetails } from "./features/appSlice";

const Router = ({ user }) => {
  // // Enable pusher logging - don't include this in production
  Pusher.logToConsole = false;
  // Get user Details from redux
  const userDetails = useSelector(selectUserDetails);
  return (
    <>
      {!user || !userDetails ? (
        <Routes>
          <Route exact path="/" element={<PatientLogin />}></Route>
          <Route exact path="/activate" element={<Invite user={user} />}></Route>
          <Route exact path="/register" element={<Register isAuthed={true} />} />
          <Route exact path="/login" element={<Login />}></Route>
          <Route path="/patient/presentation/:presentationId" element={<PatientPresentation />} />
          {/* <Route path="/presentations/*" component={NotFound} />
        <Route path="/presentations/trikafta/*" component={NotFound} /> */}
          <Route element={<NotFound />} />
        </Routes>
      ) : (
        // <PusherProvider pusher={pusher}>
        <Routes>
          <Route exact path="/" element={<Dashboard user={user} userDetails={userDetails} />}></Route>
          <Route exact path="/register" element={<Register />} user={user} />
          <Route exact path="/dashboard" element={<Dashboard user={user} userDetails={userDetails} />} />
          <Route exact path="/preferences" element={<Preferences user={user} userDetails={userDetails} />}></Route>

          <Route
            path="/admin"
            element={
              <AdminRoutes userDetails={userDetails}>
                <Admin user={user} userDetails={userDetails} />
              </AdminRoutes>
            }
          />

          <Route
            path="/clinic-account-manager"
            element={
              <SalesRepRoutes userDetails={userDetails}>
                <SalesRep user={user} userDetails={userDetails} />
              </SalesRepRoutes>
            }
          />

          <Route exact path="/edit-presentation/:presentationId" element={<EditPresentation user={user} userDetails={userDetails} />} user={user} />
          <Route exact path="/" element={<Dashboard user={user} />}></Route>
          <Route exact path="/dashboard" element={<Dashboard user={user} />} />
          <Route exact path="/preferences" element={<Preferences user={user} />}></Route>
          <Route path="/hcp/presentation/:presentationId" element={<HcpPresentation user={user} userDetails={userDetails} />} />
          <Route path="/patient/presentation/:presentationId" element={<PatientPresentation />} />
          <Route exact path="/patient" element={<PatientLogin />}></Route>
          <Route element={<NotFound />} />
        </Routes>
        // </PusherProvider>
      )}
    </>
  );
};
export default Router;
