import React from 'react'
import MainMenu from './../components/MainMenu';
import HcpFooter from './../components/HcpFooter';
import AdminInvite from './../components/Admin/AdminInvite'
import Container from 'react-bootstrap/Container'
import { db } from "./../firebase";
import { useDocumentData, useCollectionData } from "react-firebase-hooks/firestore";
import { query, collection, doc, where, getDocs } from "firebase/firestore";
import "./admin.css";


export default function Admin(props) {
  const { userDetails, user } = props
  return (
    <div className="page-wrapper">
      <MainMenu username={user.email} userDetails={userDetails}/>
      <div className="admin_page">
        <Container style={{marginTop:'2rem'}}>
          <AdminInvite {...props} />
        </Container>
      </div>
      <HcpFooter />
    </div>
  )
}
