import React, { useState } from "react";
import axios from "axios";
import { setUserId, login, setUserDetails } from "./../../features/appSlice";
import { auth } from "./../../firebase";
import firebase from "firebase/compat/app";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { default as MuiButton } from "@mui/material/Button";
import Box from "@mui/material/Box";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Formik } from "formik";
import * as Yup from "yup";
import "./RegistrationForm.css";
import { useNavigate } from "react-router-dom";

function RegistrationForm(props) {
  const navigate = useNavigate();
  const [errorMessage, setError] = useState("");
  const dispatch = useDispatch();

  const signUp = (firstname, lastname, email, password, license_number, province) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (resp) => {
        dispatch(
          setUserId({
            userId: resp.user.uid,
          })
        );

        dispatch(
          login({
            userEmail: resp.user.email,
            id: resp.user.uid,
            role: "HCP",
          })
        );

        const userData = {
          userEmail: resp.user.email,
          firstName: firstname,
          lastName: lastname,
          id: resp.user.uid,
          role: "HCP",
          favorites: [],
          license_number: license_number,
          province: province,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        };

        await createUserDoc(userData);

        dispatch(setUserDetails(userData));

        const path = `/dashboard`;
        navigate(path);
      })
      .catch(function (error) {
        let firebaseErrorCode = error.code;
        let firebaseErrorMessage = error.message;
        let errorMessage = "";

        switch (firebaseErrorCode.substr(5)) {
          case "ERROR_EMAIL_ALREADY_IN_USE":
          case "account-exists-with-different-credential":
          case "email-already-in-use":
            errorMessage = "This email address is already in use.";
            break;
          case "ERROR_WRONG_PASSWORD":
          case "wrong-password":
            errorMessage = "Wrong email/password combination.";
            break;
          case "ERROR_USER_NOT_FOUND":
          case "user-not-found":
            errorMessage = "No user found with this email address.";
            break;
          case "ERROR_USER_DISABLED":
          case "user-disabled":
            errorMessage = "This user has been disabled.";
            break;
          case "ERROR_TOO_MANY_REQUESTS":
          case "operation-not-allowed":
            errorMessage = "Too many requests to log into this account.";
            break;
          case "ERROR_OPERATION_NOT_ALLOWED":
          case "operation-not-allowed":
            errorMessage = "Server error, please try again later.";
            break;
          case "ERROR_INVALID_EMAIL":
          case "invalid-email":
            errorMessage = "Email address is invalid.";
            break;
          default:
            errorMessage = "Login failed. Please try again.";
            break;
        }
        setError(errorMessage);
        return errorMessage;
      });
  };

  async function createUserDoc(user) {
    const token = await auth.currentUser.getIdToken();
    let axiosConfig = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    const data = axios.post(`${process.env.REACT_APP_API_URL}/auth/createUserDoc`, user, axiosConfig).then(
      (response) => {
        //console.log(response);
        if (response.status === 200) {
          return response.data;
        }
      },
      (error) => {
        console.log(error);
      }
    );
    return data;
  }

  // Schema for yup
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().max(25, "*First name must be less than 100 characters").required("*First name is required"),

    lastname: Yup.string().max(25, "*Last name must be less than 100 characters").required("*Last name is required"),

    email: Yup.string().email("*Must be a valid email address").max(100, "*Email must be less than 100 characters").required("*Email is required"),

    province: Yup.string().required("*Province is required"),

    license_number: Yup.string()
      .required("*License number is required")
      .test("license-backend-validation", "Invalid license number", async (license, schema) => {
        // Res from backend will be flag at res.data.success, true for
        // username good, false otherwise
        const {
          data: { success },
        } = await axios.post(`${process.env.REACT_APP_API_URL}/verifyLicense`, { province: schema.parent.province, license: license });
        if (success) {
          return success;
        } else {
          return false;
        }
      }),
    password: Yup.string().min(6, "*Password should be at least 6 characters").max(25, "*Password must be less than 25 characters").required("*Password is required"),

    passwordConfirmation: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  return (
    <Container>
      <Formik
        initialValues={{ firstname: "", lastname: "", email: "", province: "", license_number: "", password: "" }}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);
          const signUpResponse = signUp(values.firstname, values.lastname, values.email, values.password, values.license_number, values.province);
          // Simulate submitting to database, shows us values submitted, resets form
          setTimeout(() => {
            //alert(JSON.stringify(values, null, 2));
            //resetForm();
            setSubmitting(false);
          }, 500);
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
          <Form className="registrationForm" noValidate onSubmit={handleSubmit}>
            <h2>Create Account</h2>
            <Form.Group controlId="registrationForm">
              <Form.Row>
                <Col>
                  <Form.Control type="text" placeholder="First name" name="firstname" value={values.firstname} onChange={handleChange} onBlur={handleBlur} className={touched.firstname && errors.firstname ? "error" : null} isValid={touched.firstname && !errors.firstname} isInvalid={errors.firstname} />
                  <Form.Control.Feedback type="invalid">{touched.firstname && errors.firstname ? <div className="error-message">{errors.firstname}</div> : null}</Form.Control.Feedback>
                </Col>
                <Col>
                  <Form.Control type="text" placeholder="Last name" name="lastname" onChange={handleChange} onBlur={handleBlur} value={values.lastname} className={touched.lastname && errors.lastname ? "error" : null} isValid={touched.lastname && !errors.lastname} isInvalid={errors.lastname} />
                  <Form.Control.Feedback type="invalid">{touched.lastname && errors.lastname ? <div className="error-message">{errors.lastname}</div> : null}</Form.Control.Feedback>
                </Col>
              </Form.Row>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address:</Form.Label>
              <Form.Control type="email" placeholder="Enter your email address" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} className={touched.email && errors.email ? "error" : null} isValid={touched.email && !errors.email} isInvalid={errors.email} />
              <Form.Control.Feedback type="invalid">{touched.email && errors.email ? <div className="error-message">{errors.email}</div> : null}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label>Licensing province/territory: </Form.Label>
              <Form.Control as="select" name="province" custom onChange={handleChange} onBlur={handleBlur}>
                <option value=""></option>
                <option value="Alberta">Alberta</option>
                <option value="British Columbia">British Columbia</option>
                <option value="Manitoba">Manitoba</option>
                <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                <option value="Northwest Territories">Northwest Territories</option>
                <option value="Nova Scotia">Nova Scotia</option>
                <option value="Nunavut">Nunavut</option>
                <option value="Ontario">Ontario</option>
                <option value="Prince Edward Island">Prince Edward Island</option>
                <option value="Quebec">Quebec</option>
                <option value="Saskatchewan">Saskatchewan</option>
                <option value="Yukon">Yukon</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formBasicLicenseNumber">
              <Form.Label>License number:</Form.Label>
              <Form.Control type="license_number" placeholder="License number" name="license_number" onChange={handleChange} onBlur={handleBlur} value={values.license_number} className={touched.license_number && errors.license_number ? "error" : null} isValid={touched.license_number && !errors.license_number} isInvalid={errors.license_number} />
              <Form.Control.Feedback type="invalid">{touched.license_number && errors.license_number ? <div className="error-message">{errors.license_number}</div> : null}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password:</Form.Label>
              <Form.Control type="password" placeholder="Password" name="password" onChange={handleChange} onBlur={handleBlur} value={values.password} className={touched.password && errors.password ? "error" : null} isValid={touched.password && !errors.password} isInvalid={errors.password} />
              <Form.Control.Feedback type="invalid">{touched.password && errors.password ? <div className="error-message">{errors.password}</div> : null}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Confirm password: </Form.Label>
              <Form.Control type="password" placeholder="Confirm password" name="passwordConfirmation" onChange={handleChange} onBlur={handleBlur} value={values.passwordConfirmation} className={touched.passwordConfirmation && errors.passwordConfirmation ? "error" : null} isValid={touched.passwordConfirmation && !errors.passwordConfirmation} isInvalid={errors.passwordConfirmation} />
              <Form.Control.Feedback type="invalid">{touched.passwordConfirmation && errors.passwordConfirmation ? <div className="error-message">{errors.passwordConfirmation}</div> : null}</Form.Control.Feedback>
            </Form.Group>

            <Button type="submit" variant="dark w-100" className="submit">
              Register
            </Button>
            {errorMessage ? <div className="error-message-register">{errorMessage}</div> : null}
            <Box sx={{ pt: 1, display: "flex", justifyContent: "flex-end" }} justifyContent="end" alignItems="center">
              <span>Already have an account? </span>
              <MuiButton href="/login" className="login_link">
                {" "}
                Login here
              </MuiButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
export default RegistrationForm;
