import React, { useState, useEffect } from "react";
import { auth } from "./firebase";
import Carousel from "react-bootstrap/Carousel";
import PresentationFooterControls from "./components/HcpPresentation/PresentationFooterControls";
import { getPresentationSlides } from "./js/utils";
import "./Presentation.css";

function Presentation({ slide, handleSelect, isPatient, presentationId, presentation, handleToggleQR, togglePatientView, handleClosingAlertOpen, presentationLanguage }) {
  const [presentationData, setPresentationData] = useState();
  const hcpPatientClass = isPatient ? "patient" : "hcp";

  //get presentation data and only rerender if isPatient changes
  useEffect(() => {
    getPresentationSlides(presentation, isPatient, presentationLanguage).then((slideData) => {
      setPresentationData(slideData);
    });
  }, [isPatient]);

  if (presentationData) {
    return (
      <div className={`presentationWrapper ${hcpPatientClass}`} key={hcpPatientClass}>
        {isPatient ? (
          <Carousel activeIndex={slide} onSelect={handleSelect} interval={null} controls={false} touch={false} indicators={false}>
            {presentationData?.map((slide, index) => (
              <Carousel.Item key={index}>
                <img className="d-block w-100" src={slide.src} alt={slide.slideName} />
                <div key={index} dangerouslySetInnerHTML={{ __html: slide.html }}></div>
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <Carousel activeIndex={slide} onSelect={handleSelect} interval={null} controls={true} touch={true} indicators={false}>
            {presentationData?.map((slide, index) => (
              <Carousel.Item key={"carousel" + index}>
                <img
                  className="d-block w-100"
                  srcSet={` ${slide.srcXL} 2552w, 
              ${slide.srcL} 1366w,
              ${slide.srcM} 1100w,
              ${slide.src} 950w,
              `}
                  sizes="100vw"
                  src={slide.src}
                  alt={slide.slideName}
                />
                <div dangerouslySetInnerHTML={{ __html: slide.html }}></div>
              </Carousel.Item>
            ))}
          </Carousel>
        )}
        {!isPatient || auth.currentUser ? <PresentationFooterControls slideData={presentationData} slide={slide} handleSelect={handleSelect} handleToggleQR={handleToggleQR} togglePatientView={togglePatientView} handleClosingAlertOpen={handleClosingAlertOpen} /> : null}
      </div>
    );
  } else {
    return <div>Loading..</div>;
  }
}

export default Presentation;
