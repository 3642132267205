import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { db, auth } from "./../../firebase";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Col from "react-bootstrap/Col";
import { Formik, ErrorMessage } from "formik";
import { useNavigate } from "react-router";
import * as Yup from "yup";

function EditAccountInformationFormAdmin(props) {
  const navigate = useNavigate();
  const { user, userDetails } = props;
  const userId = user.uid;

  if (!userDetails || !user) {
    navigate({
      pathname: "/login",
    });
  }

  const [errorMessage, setError] = useState("");
  const [successMessage, setSuccess] = useState("");

  const updateAccount = async (firstname, lastname, email) => {
    // Update the user info in firestore db
    if (email === userDetails?.userEmail) {
      // const userRef = doc(db, "users", userId);

      const userDetails = {
        id: userId,
        firstName: firstname,
        lastName: lastname,
        timestamp: serverTimestamp(),
      };
      await updateUserDoc(userDetails);

      setSuccess("Account information updated successfully!");
    } else {
      //only goes here if someone tries to hack the disabled field on the form
      setError("Email address cannot be changed");
    }
  };

  async function updateUserDoc(user) {
    const token = await auth.currentUser.getIdToken();
    let axiosConfig = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    const data = axios.put(`${process.env.REACT_APP_API_URL}/auth/updateUser/${user.id}`, user, axiosConfig).then(
      (response) => {
        //console.log(response);
        if (response.status === 200) {
          console.log("response", response);
          return response.data;
        }
      },
      (error) => {
        console.log(error);
      }
    );
    return data;
  }

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().max(25, "*First name must be less than 100 characters").required("*First name is required"),

    lastname: Yup.string().max(25, "*Last name must be less than 100 characters").required("*Last name is required"),

    email: Yup.string().email("*Must be a valid email address").max(100, "*Email must be less than 100 characters").required("*Email is required"),
  });

  return (
    <div>
      {userDetails && (
        <Formik
          initialValues={{
            firstname: userDetails?.firstName,
            lastname: userDetails?.lastName,
            email: userDetails?.userEmail,
          }}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={false}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            const updateAccountResponse = updateAccount(values.firstname, values.lastname, values.email);
            // Simulate submitting to database, shows us values submitted, resets form
            setTimeout(() => {
              setSubmitting(false);
            }, 500);
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
            <Form className="updateAccountForm" noValidate onSubmit={handleSubmit}>
              <h4>Update account information</h4>
              <Form.Group controlId="updateAccountForm">
                <Form.Row>
                  <Col>
                    <Form.Label>First name:</Form.Label>
                    <Form.Control type="text" placeholder="First name" name="firstname" value={values.firstname} onChange={handleChange} onBlur={handleBlur} className={touched.firstname && errors.firstname ? "error" : null} isValid={touched.firstname && !errors.firstname} isInvalid={errors.firstname} />
                    <Form.Control.Feedback type="invalid">{touched.firstname && errors.firstname ? <div className="error-message">{errors.firstname}</div> : null}</Form.Control.Feedback>
                  </Col>
                  <Col>
                    <Form.Label>Last name:</Form.Label>
                    <Form.Control type="text" placeholder="Last name" name="lastname" onChange={handleChange} onBlur={handleBlur} value={values.lastname} className={touched.lastname && errors.lastname ? "error" : null} isValid={touched.lastname && !errors.lastname} isInvalid={errors.lastname} />
                    <Form.Control.Feedback type="invalid">{touched.lastname && errors.lastname ? <div className="error-message">{errors.lastname}</div> : null}</Form.Control.Feedback>
                  </Col>
                </Form.Row>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address:</Form.Label>
                <Form.Control type="email" disabled placeholder="Enter your email address" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} className={touched.email && errors.email ? "error" : null} isValid={!errors.email} isInvalid={errors.email} />
                <Form.Control.Feedback type="invalid">{touched.email && errors.email ? <div className="error-message">{errors.email}</div> : null}</Form.Control.Feedback>
              </Form.Group>

              <Button disabled={!isValid} type="submit" variant="dark w-100" className="submit">
                Update
              </Button>

              <Stack sx={{ width: "100%", marginTop: 2 }} spacing={2}>
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
                {successMessage ? <Alert severity="success">{successMessage}</Alert> : null}
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
export default EditAccountInformationFormAdmin;
