// PrivateRoute.tsx in v6
import { Navigate, Route, useLocation } from "react-router-dom";
import { auth, db } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { isAdmin } from "./js/utils";

const AdminRoutes = (props, { children }) => {
  const [user, loading] = useAuthState(auth);
  let location = useLocation();

  if (isAdmin(props.userDetails.role)) {
    return props.children;
  }
  return <Navigate to="/" state={{ from: location }} />;
};

export default AdminRoutes;
