import React, { useState } from 'react'
import { setUserId, login } from './../../features/appSlice'
import { auth } from './../../firebase'
import { sendPasswordResetEmail } from "firebase/auth";
import { useDispatch } from 'react-redux' 
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {default as MuiButton} from '@mui/material/Button'; 
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Container from 'react-bootstrap/Container'
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "./ResetPasswordForm.css";

function ResetPassword(props){
  const [errorMessage, setError] = useState('');
  const [successMessage, setSuccess] = useState('');

  const dispatch = useDispatch()

  const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess("A password reset link has been sent to your email address!")
    } catch (error) {
      let firebaseErrorCode = error.code;
      let errorMessage = '';

      switch (firebaseErrorCode.substr(5)) {
        case 'ERROR_USER_NOT_FOUND':
        case 'user-not-found':
          errorMessage = 'No account found with this email address.';
          break;
        case 'ERROR_USER_DISABLED':
        case 'user-disabled':
          errorMessage = 'This user has been disabled.';
          break;
        case 'ERROR_TOO_MANY_REQUESTS':
        case 'operation-not-allowed':
          errorMessage = 'Too many requests to log into this account.';
          break;
        case 'ERROR_OPERATION_NOT_ALLOWED':
        case 'operation-not-allowed':
          errorMessage = 'Server error, please try again later.';
          break;
        case 'ERROR_INVALID_EMAIL':
        case 'invalid-email':
          errorMessage = 'Email address is invalid.';
          break;
        default:
          errorMessage = 'Reset Password failed. Please try again.';
          break;
      }
      setError(errorMessage);
    }
  };

	// Schema for yup
	const validationSchema = Yup.object().shape({
    email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  });
  
  return (
  <Container>
    <Formik
    initialValues={{ firstname:"", lastname:"", email:"", province:"", license_number:"", password:""}}
    validationSchema={validationSchema}
    onSubmit={(values, {setSubmitting, resetForm}) => {
      // When button submits form and form is in the process of submitting, submit button is disabled
      setSubmitting(true);
      const signUpResponse = sendPasswordReset(values.email)
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    }}
    >
    {({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    isValid,
    errors,
    }) => (
    <Form className="ResetPasswordForm" noValidate onSubmit={handleSubmit}>
    <h2>Reset Your Password</h2>
      <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address:</Form.Label>
          <Form.Control 
            type="email" 
            placeholder="Enter your email address"
            name="email"
            onChange={handleChange} 
            onBlur={handleBlur}
            value={values.email}
            className={touched.email && errors.email ? "error" : null}
            isValid={touched.email && !errors.email}
            isInvalid={errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {touched.email && errors.email ? (
                <div className="error-message">{errors.email}</div>
              ): null}
            </Form.Control.Feedback>
      </Form.Group>
      
      <Button type="submit" variant="dark w-100">Reset Password</Button>

      <Stack sx={{ width: '100%', marginTop:2 }} spacing={2}>
        {successMessage ? ( <Alert severity="success">{successMessage}</Alert> ): null}
        {errorMessage ? ( <Alert severity="error">{errorMessage}</Alert> ): null}
      </Stack>
      
      <div className="d-flex justify-content-end mt-2"><MuiButton href="/login" className="login_link">&#8592; Back to login</MuiButton></div>
    </Form>
    )}
  </Formik>
</Container>
  )
};
export default ResetPassword;