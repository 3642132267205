import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

export default function ChoosePresentationLanguage(props) {
  const { presentationID, presentationLanguage, handlePresentationLanguageChange, index, languages } = props;
  return (
    <FormControl component="fieldset" sx={{ borderBottom: "1px solid #E3E3E3", width: "100%", px: 2 }} key={presentationID}>
      <RadioGroup row aria-label="language" name="controlled-radio-buttons-group" value={presentationLanguage[0][presentationID]} onChange={(event) => handlePresentationLanguageChange(event, presentationID, index)} sx={{ pt: 1 }} defaultValue="English">
        {languages?.map((language) => {
          return (
            <FormControlLabel
              value={language}
              key={language}
              control={
                <Radio
                  size="small"
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                      pr: 0,
                    },
                  }}
                />
              }
              label={
                <Typography variant="body2" color="primary">
                  {language}
                </Typography>
              }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
