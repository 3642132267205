import React, { useState, useEffect } from "react";
import axios from "axios";
import { auth, db, analytics } from "./../firebase";
import { doc, deleteDoc } from "firebase/firestore";
// import { useCollection } from 'react-firebase-hooks'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useLocation, useNavigate } from "react-router-dom";
import { default as MuiButton } from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { selectUser } from "./../features/appSlice";
import { sendPatientEmail } from "./../js/patientEmail";
import Presentation from "./../Presentation";
import PresentationUsers from "./../components/HcpPresentation/PresentationUsers";
import MainMenuPresentation from "./../components/MainMenuPresentation";
import PresentationInviteModal from "./../components/HcpPresentation/PresentationInviteModal";
import HcpFooter from "./../components/HcpFooter";
import { logEvent } from "firebase/analytics";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Pusher from "pusher-js";
// import Pusher from 'pusher-js/with-encryption';

function HcpPresentation(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [pusherChannel, setPusherChannel] = useState(null);
  const [presentation, setPresentation] = useState(location.state.presentation);
  const [presentationId, setPresentationId] = useState(location.state.presentationId);
  const [sendFollowUpEmail, setSendFollowUpEmail] = useState(location.state.sendFollowUpEmail);
  const [username, setUsername] = useState(props.user.email);
  // presentation users that are currently viewing the presentation (connected to pusherjs on member added/removed)
  const [presentationUsers, setPresentationUsers] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [slide, setSlide] = useState(0);
  const [requestedAccess, setRequestedAccess] = useState(false);
  const [requestedAccessUsername, setRequestedAccessUsername] = useState([]);
  const [showQRCode, setShowQRCode] = useState(false);
  const [presentationView, setPresentationView] = useState(false);
  const user = useSelector(selectUser);
  const presentationName = location.state.presentationName;
  const presentationLanguage = location.state.presentationLanguage;
  const [openClosingAlert, setClosingAlert] = React.useState(false);
  const [meetingEnded, setMeetingEnded] = useState(false);
  // The approved users (separate from presentationUsers) used for email since users can leave presentation before HCP ends it
  const [approvedUsers, setApprovedUsers] = useState([]);

  const EndMeetingText = () => {
    if (sendFollowUpEmail) {
      return "Ending the session will send the patient follow-up email to all patients that have joined the meeting. The session will no longer be available after ending.";
    }

    return "The session will no longer be available for the patient after ending.";
  };

  const handleClosingAlertOpen = () => {
    setClosingAlert(!openClosingAlert);
  };

  const handleClosingAlertClose = () => {
    setClosingAlert(false);
  };

  // When HCP leaves presentation -> delete doc from activePresentations and requestAccess
  const endMeeting = async () => {
    handleClosingAlertClose();

    // Send patient email if more than one presentationUsers (meaning there is a patient)
    // Check to ensure meetingEnded is false to ensure the email only sends once since we are also hooking
    // into the onbeforeunload event (which doesnt always fire on the browser and not at all on IOS)
    if (!meetingEnded) {
      if (approvedUsers.length > 0 && sendFollowUpEmail === true) {
        sendPatientEmail(presentationName, approvedUsers, username, presentationLanguage, props.userDetails);
        setMeetingEnded(true);
      }

      // Remove presentation record from Firestore activePresentations and requestAccess collection
      await deleteDoc(doc(db, "activePresentations", presentationId));

      await deleteDoc(doc(db, "requestAccess", presentationId));

      logEvent(analytics, `${presentation}_gyd_completed`, {
        user_id: auth.currentUser.uid,
        language: presentationLanguage === "English" ? "en" : "fr",
      });

      // Run the end meeting code (send patient email)
      endMeetingForPatient().then(function () {
        // console.log('Done')
        //navigate('/dashboard')
      });

      navigate("/dashboard");
    }
  };
  useEffect(() => {
    // Requested access from patient to presentation
    logEvent(analytics, `${presentation}_gyd_started`, {
      app_user_id: auth.currentUser.uid,
      user_id: auth.currentUser.uid,
      language: presentationLanguage === "English" ? "en" : "fr",
    });
    logEvent(analytics, "page_view", {
      page_location: `presentation/${presentation}/slide/1`,
      user_id: auth.currentUser.uid,
      language: presentationLanguage === "English" ? "en" : "fr",
      page_title: `${presentationName} Slide 1`,
      app_user_id: auth.currentUser.uid,
    });
  }, []);

  // Unbind and Rebind to requestAccess pusher event (this is so we can get an updated state object after binding)
  useEffect(() => {
    // Requested access from patient to presentation
    if (pusherChannel && pusherChannel.bind) {
      pusherChannel.unbind("requestAccess");
      pusherChannel.bind("requestAccess", (data) => {
        setRequestedAccessUsername([...requestedAccessUsername, data.username]);
      });
    }
  }, [pusherChannel, requestedAccessUsername]);

  useEffect(() => {
    window.addEventListener("beforeunload", endMeeting);
    // Set presentation id and username
    if (location.state) {
      const presentation = location.state.presentation;
      const presentationId = location.state.presentationId;
      const username = location.state.username;
      setPresentation(presentation);
      setPresentationId(presentationId);
      setUsername(username);
    }

    // Connnect to pusher instance
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: "us2",
      encrypted: true,
      authEndpoint: `${process.env.REACT_APP_API_URL}/auth`,
      auth: {
        params: { username: auth.currentUser.email, uid: auth.currentUser.uid, role: "HCP" },
      },
    });

    // Subscribe to presentation channel (this creates the presence channel in pusherjs)
    const presentation_channel = pusher.subscribe(`presence-${presentationId}`);
    setPusherChannel(presentation_channel);

    // Bind to slide event on channels
    presentation_channel.bind("slide", (data) => {
      setSlide(data.slide);
    });

    // Subscription to presentation presence channel succeedeed
    presentation_channel.bind("pusher:subscription_succeeded", (member) => {
      // var me = presentation_channel.members.me;
      // var userInfo = me.info;
      // setUserCount(presentation_channel.members.count)
      //   const memberArray = [];
      //   //presentation_channel.members.members.map(member => console.log('presentationUser', member) );
      //   Object.values(presentation_channel.members.members).forEach(async function(member) {
      //     let memberID = member.myID;
      //     memberArray.push( member.name );
      //   })
      //   setPresentationUsers(memberArray)
    });

    // On member added to presentation presence channel
    presentation_channel.bind("pusher:member_added", (member) => {
      // console.log('member', member);
      const memberArray = [];

      setUserCount(presentation_channel.members.count);
      setPresentationUsers((presentationUsers) => [...presentationUsers, member.info.name]);
    });

    // User removed from presentation presence channel
    presentation_channel.bind("pusher:member_removed", (member) => {
      setUserCount(presentation_channel.members.count);
      setPresentationUsers((presentationUsers) => presentationUsers.filter((name) => name !== member.info.name));
    });

    // On unmount unsub from presence channel and unbind events - also disconnect from presence channel
    return () => {
      window.removeEventListener("beforeunload", endMeeting);
      presentation_channel.unbind();
      pusher.unsubscribe(`presence-${presentationId}`);
      pusher.disconnect();
    };
  }, []);

  // Ends the meeting for HCP and Patients
  const endMeetingForPatient = async () => {
    const token = await auth.currentUser.getIdToken();
    let axiosConfig = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const payload = {
      presentationId: presentationId,
    };
    // End the meeting and send event to patients
    axios.post(`${process.env.REACT_APP_API_URL}/endMeeting`, payload, axiosConfig);
  };

  // Grant presentation access
  const grantAccess = async (user) => {
    const token = await auth.currentUser.getIdToken();
    let axiosConfig = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const payload = {
      requestedAccessUsername: user,
      presentationId: presentationId,
    };

    //grant user access - send event to user
    setApprovedUsers([...approvedUsers, user]);
    // console.log('requestedAccessUsername', requestedAccessUsername)

    const newSetRequestedAccessUsername = [...requestedAccessUsername];
    newSetRequestedAccessUsername.splice(requestedAccessUsername.indexOf(user), 1);

    setRequestedAccessUsername(newSetRequestedAccessUsername);

    axios.post(`${process.env.REACT_APP_API_URL}/grantAccess`, payload, axiosConfig);
  };

  // Deny presentation access
  const denyAccess = async (user) => {
    const token = await auth.currentUser.getIdToken();
    let axiosConfig = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const payload = {
      requestedAccessUsername: user,
      presentationId: presentationId,
    };

    // Deny user access - send event to API and broadcast event to users
    const newSetRequestedAccessUsernameDeny = [...requestedAccessUsername];
    newSetRequestedAccessUsernameDeny.splice(requestedAccessUsername.indexOf(user), 1);

    setRequestedAccessUsername(newSetRequestedAccessUsernameDeny);

    setRequestedAccess(false);
    axios.post(`${process.env.REACT_APP_API_URL}/denyAccess`, payload, axiosConfig);
  };

  // On presentation slide change
  const handleSelect = async (selectedIndex, e) => {
    const token = await auth.currentUser.getIdToken();
    let axiosConfig = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    // Scroll to thumbnail
    let elmnt = e.target;
    elmnt.scrollIntoView({ block: "end", inline: "center" });

    // Set active slide
    setSlide(selectedIndex);
    console.log("presentation", presentation);

    let slideIndex = selectedIndex + 1;
    // Log slide page view to Google Analytics
    logEvent(analytics, "page_view", {
      page_location: `presentation/${presentation}/slide/${slideIndex}`,
      user_id: auth.currentUser.uid,
      language: presentationLanguage === "English" ? "en" : "fr",
      page_title: `${presentationName} Slide ${slideIndex}`,
    });

    const payload = {
      username: username,
      slide: selectedIndex,
      presentationId: presentationId,
    };

    axios.post(`${process.env.REACT_APP_API_URL}/slide`, payload, axiosConfig);
  };

  // show or hide the QR Code
  const handleToggleQR = () => {
    setShowQRCode(!showQRCode);
  };

  const togglePatientView = () => {
    setPresentationView(!presentationView);
  };

  return (
    <div className="App hcpPresentation">
      <div className="requestedAccessToast" key={requestedAccess}>
        <Modal className="hcpPresentation__invite_patient" show={requestedAccessUsername.length !== 0} onHide={denyAccess} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>
              <strong className="mr-auto">Patient access request</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {requestedAccessUsername?.map((user, index) => (
              <Box sx={{ p: 2, borderBottom: 1, borderTop: 1, borderColor: "divider", textAlign: "center", justifyContent: "center" }} key={user}>
                <p>Access request for: {user}</p>

                <Stack sx={{ justifyContent: "center" }} direction="row" spacing={2}>
                  <MuiButton variant="contained" color="success" onClick={() => grantAccess(user)}>
                    Grant Access
                  </MuiButton>
                  <MuiButton variant="contained" color="error" onClick={() => denyAccess(user)}>
                    Deny Access
                  </MuiButton>
                </Stack>
              </Box>
            ))}
          </Modal.Body>
        </Modal>
      </div>

      <MainMenuPresentation username={username} inPresentation={true} handleClosingAlertOpen={handleClosingAlertOpen} />

      <header className="App-header">
        <Container fluid={true}>
          <Row>
            <Col>
              <div className="row viewingPresentation">
                <PresentationUsers presentationUsers={presentationUsers} />
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      <Presentation slide={slide} handleSelect={handleSelect} presentation={presentation} isPatient={presentationView} handleToggleQR={handleToggleQR} togglePatientView={togglePatientView} handleClosingAlertOpen={handleClosingAlertOpen} presentationLanguage={presentationLanguage} />

      <PresentationInviteModal presentation={presentation} presentationId={presentationId} showQRCode={showQRCode} handleToggleQR={handleToggleQR} />

      <div>
        <Dialog open={openClosingAlert} onClose={handleClosingAlertClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" sx={{ color: "#ffffff", backgroundColor: "#f44336", marginBottom: "20px;" }}>
            {"Are you sure you want to end this meeting?"}
          </DialogTitle>
          <DialogContent sx={{ paddingTop: "20px" }}>
            <DialogContentText id="alert-dialog-description" sx={{ color: "#000000" }}>
              <EndMeetingText />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MuiButton onClick={handleClosingAlertClose} variant="contained">
              {" "}
              Cancel{" "}
            </MuiButton>
            <MuiButton onClick={endMeeting} autoFocus color="red" variant="contained">
              {" "}
              End Meeting{" "}
            </MuiButton>
          </DialogActions>
        </Dialog>
      </div>
      <HcpFooter />
    </div>
  );
}

export default HcpPresentation;
