import { Navigate, useLocation } from "react-router-dom";
import { isSalesRep } from './js/utils'

const SalesRepRoutes = (props, { children }) => {
  let location = useLocation();

  if (isSalesRep(props.userDetails.role)) {
    return props.children;
  }
  return <Navigate to="/" state={{ from: location }} />;
  
};

export default SalesRepRoutes;