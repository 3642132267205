import React, { useState, useEffect } from 'react'
import { db } from '../../firebase'
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { useDispatch } from 'react-redux'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Input from '@mui/material/Input';
import {default as MuiButton} from '@mui/material/Button';
import { replaceRegisteredTradeMark } from './../../js/utils';
import Box from '@mui/material/Box';
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { Formik } from 'formik';
import * as Yup from 'yup';
import CardMedia from '@mui/material/CardMedia';
import { storage } from './../../firebase';
import { ref, getDownloadURL, uploadString } from "firebase/storage";
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';


function EditPresentationForm(props){
  const navigate = useNavigate();
  const [errorMessage, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [presentationData, setPresentationData] = useState('');
  const [thumbnailUrl, setThumbUrl] = useState('');
  const [file, setFile] = useState(null);

  //console.log('presentationData = '+presentationData);

  useEffect(() => {
    getPresentationData()
  }, [])

  useEffect(() => {
    if(presentationData) {
     if (!presentationData.ownerID || !presentationData.ownerID.includes(props.userDetails.id) ) {
        navigate('/dashboard')
     }
    }
  }, [presentationData])


  const getPresentationData = async (title, description, thumb) => {

    const docRef = doc(db, "presentations", props.presentationId);
    const docSnap = await getDoc(docRef);
    setPresentationData(docSnap.data())

  }

  const updatePresentation = async (title, description, thumb) => {
    const thumbRef = ref(storage, `${props.presentationId}.png`);
    const presentationRef = doc(db, "presentations", props.presentationId);
    let imgRef = "";
    let imgUrl = "";

    if(file){
      uploadString(thumbRef, file, 'base64').then((snapshot) => {
        imgRef = ref(storage, snapshot.metadata.fullPath);
       
        // Get the download URL
        getDownloadURL(imgRef).then(async(url) => {
          setThumbUrl(url);
          imgUrl = url;

          // Insert url into an <img> tag to "download"
          await updateDoc(presentationRef, {
            presentationName: title,
            presentationDescription: description,
            presentationThumbnail: imgUrl
          }).then(() => {
            setSuccessMessage('Success!')
          }).catch((error) => {
            setError('ERROR')
          })
        })
        .catch((error) => {
          setError('ERROR')
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
  
            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              break;
          }
        });
      });
    }
  
    else{
        await updateDoc(presentationRef, {
          presentationName: title,
          presentationDescription: description
        }).then(() => {
          setSuccessMessage('Success!')
        }).catch((error) => {
          console.log(error);
          setError('ERROR')
        });
      }

  }

  const imageChanger = (e) => {
    const imgTypes = ['image/png','image/jpeg'];
    let userImage = e.target.files[0];
    let reader = new FileReader();
    let imgText = document.querySelector(".editPresentationForm__imgText");
    const subButton = document.querySelector(".submit");
    imgText.innerHTML = e.target.files[0].name;
    reader.readAsDataURL(userImage);
    reader.onload = (e) => {
      //setFile(e.target.result);
      var base64result = reader.result.substr(e.target.result.indexOf(',') + 1);
      setFile(base64result);
    }
    
    if(userImage && imgTypes.includes(userImage.type)){
      setFile(e.target.files[0]);
      setError('');
      subButton.disabled = false;
    }else{
      setFile(null);
      setSuccessMessage('')
      setError('Please select an image file (png or jpeg)');
      subButton.disabled = true;
    }

  }
    // Schema for yup
  const validationSchema = Yup.object().shape({
    title: Yup.string()
    .max(50, "*Title must be less than 50 characters")
    .required("*Title is required"),
    description: Yup.string()
    .max(500, "*Description must be less than 500 characters")
    .required("*Description is required")
  });  

  return (
  <Container>
    { presentationData !== '' && (
    <Formik
    initialValues={{ title:presentationData.presentationName, description:presentationData.presentationDescription, thumb:presentationData.presentationThumbnail}}
    validationSchema={validationSchema}
    validateOnChange={true}
    validateOnBlur={false}
    onSubmit={(values, {setSubmitting}) => {
      // When button submits form and form is in the process of submitting, submit button is disabled
      setSubmitting(true);
      const signUpResponse = updatePresentation(values.title, values.description, values.thumb)
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    }}
    >
    {({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    isValid,
    errors,
    }) => (
    <Form className="editPresentationForm" noValidate onSubmit={handleSubmit}>
    <h2 className="editPresentationForm__page_title">Edit: {replaceRegisteredTradeMark(presentationData.presentationName)}</h2>
      <Form.Group controlId="editPresentationForm">
        <Form.Row>
          <Col>
            <InputLabel htmlFor="presentationTitle">Title</InputLabel>
            <Form.Control
            label="Edit Title"
              type="text"
              placeholder="Title"
              name="title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              className={touched.title && errors.title ? "error" : null}
              isValid={touched.title && !errors.title}
              isInvalid={errors.title}
              />
            <Form.Control.Feedback type="invalid">
              {touched.title && errors.title ? (
                <div className="error-message">{errors.title}</div>
              ): null}
            </Form.Control.Feedback>
          </Col>
          </Form.Row>
          <Form.Row>
          <Col>
          <InputLabel htmlFor="presentationDesc">Description</InputLabel>
            <Form.Control
              type="text"
              as="textarea"
              placeholder="Description"
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              className={touched.description && errors.description ? "error" : null}
              isValid={touched.description && !errors.description}
              isInvalid={errors.description}
              />
              <Form.Control.Feedback type="invalid">
                {touched.description && errors.description ? (
                  <div className="error-message">{errors.description}</div>
                ): null}
              </Form.Control.Feedback>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>

          <div className='editPresentationForm__image-uploader'>
              <p className="editPresentationForm__imgText">Upload Thumbnail Image</p>
              <label htmlFor="editPresentationForm__input">
                <Input accept="image/*" id="editPresentationForm__input" type="file" onChange={imageChanger}/>
                <IconButton className="editPresentationForm_icon-button-file" color="primary" aria-label="upload picture" component="span">
                  <PhotoCamera />
                </IconButton>
              </label>
          </div>
          
          </Col>
        </Form.Row>
      </Form.Group>

      {successMessage && thumbnailUrl ? ( 
        <div className="editPresentationForm__success-message-edit" style={{marginBottom:'25px'}}>
          <CardMedia
            component="img"
            image={thumbnailUrl} 
            alt={presentationData.presentationName}
          />
        </div> ): null}
      <Button type="submit" variant="dark w-100" className="submit">Update</Button>
        {errorMessage ? ( <Alert sx={{marginTop:'20px'}} severity='error'>{errorMessage}</Alert> ): null}
        {successMessage ? ( <Alert sx={{marginTop:'20px'}} severity='success'>{successMessage}</Alert> ): null}
      {errorMessage ? ( <div className="error-message-edit">{errorMessage}</div> ): null}
     
    </Form>
    )}
  </Formik>
  )}
</Container>
  )
};
export default EditPresentationForm;

