import React, { useState } from 'react'
import { auth, db } from "./../../firebase";
import firebase from "firebase/compat/app";
import { query, collection, doc, where } from "firebase/firestore";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Form from 'react-bootstrap/Form'
import { Formik, ErrorMessage } from 'formik';
import {default as MuiButton} from '@mui/material/Button'; 
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import * as Yup from 'yup';
import axios from 'axios';
import Fade from '@mui/material/Fade';


export default function InviteSalesRep(props) {
  const [errorMessage, setError] = useState('');
  const [successMessage, setSuccess] = useState('');
  const [fade, setFade] = useState(false);
  const companyUsers = props.companyCollectionUsers
  const company = props.company

  const section = {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff"
  };


  const inviteUser = async (email) => {
    if (props.companyInvitations.some(e => e.company === company && e.userEmail === email)) {
      setFade(true)
      setError(`This user has already been invited to ${company}`)
      const timer = setTimeout(() => {
        setError('')
        setFade(false)
      }, 5000);
      return
    }

    //User already added to company
    if (companyUsers.some(e => e.userEmail === email)) {
      setSuccess('')
      setError(`This user has already been added to ${company}`)
      setFade(true)

      const timer = setTimeout(() => {
        setError('')
        setFade(false)
      }, 5000);

      return;
    }

    const userData = {
      id:'1234567',
      userEmail:email,
      role:'Clinic Account Manager',
      status:'pending',
      company: company
    }

    const token = await auth.currentUser.getIdToken();
    let axiosConfig = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }

    axios.post(`${process.env.REACT_APP_API_URL}/admin/inviteUser`, userData, axiosConfig).then((response) => {
      // console.log(response.data.message);
      props.getCompanyUsers();
    }, (error) => {
        console.log(error);
    });
      setSuccess(`${email} has been invited successfully.`)
      setFade(true)
      const timer = setTimeout(() => {
        setSuccess('')
        setFade(false)
      }, 5000);


  };

    // Schema for yup
  const validationSchema = Yup.object().shape({
    email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*Email is required"),
  });

  return (
    <div className="admin_page__invite_sales_rep">
      <Formik
        initialValues={{ email:""}}
        validationSchema={validationSchema}
        onSubmit={(values, {setSubmitting, resetForm}) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);
          const signUpResponse = inviteUser(values.email)
          setTimeout(() => {
            setSubmitting(false);
          }, 500);
        }}
      >
        {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        }) => (
        <Form id="InviteSalesRepForm" className="InviteSalesRepForm" noValidate onSubmit={handleSubmit}>
          <Box sx={{ flexGrow: 1 }}>

            <Form.Label><strong>Invite a Clinic Account Manager:</strong></Form.Label>
            <Grid container spacing={2} alignItems="center">
            
              <Grid item xs={12} md={8}>   
                <div style={section}>   
                  <Form.Group controlId="formBasicEmail" style={{marginBottom:'0px'}}>
                    <Form.Control 
                      type="email" 
                      placeholder="Enter the clinic account manager email address"
                      name="email"
                      onChange={handleChange} 
                      onBlur={handleBlur}
                      value={values.email}
                      className={touched.email && errors.email ? "error" : null}
                      isValid={touched.email && !errors.email}
                      isInvalid={errors.email}
                      />
                  </Form.Group>

                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div style={section}> 
                  <MuiButton sx={{width: '100%'}} color="primary" type="submit" variant="contained">Invite</MuiButton>
                </div> 
              </Grid>
            </Grid>
          </Box>
          
          <Form.Control.Feedback type="invalid">
                        {touched.email && errors.email ? (
                          <div className="error-message">{errors.email}</div>
                        ): null}
                      </Form.Control.Feedback>

            <Stack sx={{ width: '100%', marginTop:2 }} spacing={2}>
              {fade && successMessage ? ( <Fade in={fade}><Alert severity="success">{successMessage}</Alert></Fade> ): null}
              {fade && errorMessage ? ( <Fade in={fade}><Alert severity="error">{errorMessage}</Alert></Fade> ): null}
            </Stack>
          
          </Form>
        )}
      </Formik>
    </div>
  )
}
