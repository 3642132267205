import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { auth } from "./../firebase"
import { useNavigate } from "react-router-dom";

const MainMenuPresentation = (props) => {

  let navigate = useNavigate();

  // Sign Out the user
  const signOut = () => {
    auth.signOut();
    navigate('/login')
  }

  const goToPreferences = () => {
    const path = `/preferences`
    navigate({ 
      pathname: path,
      state: { 
        username: props.user,
      }
    });
  }

  const userLoggedInPresentation = () => {
    // Logged in and in a presentation
    if(props.inPresentation && props.username) {
      return (
        <Nav.Link onClick={() => props.handleClosingAlertOpen()} className="dashboard_link">
          <DashboardIcon className="mainMenu__home-icon"/>Dashboard
        </Nav.Link> 
      )
    }
    // Logged in but not in a presentation
    else if (props.username  && !props.inPresentation ){
      return (
        <Nav.Link href="/dashboard" className="dashboard_link">
          <DashboardIcon className="mainMenu__home-icon"/>Dashboard
        </Nav.Link> 
      )
    }
    // Not logged in
    else {
      return (
        <Nav.Link href="/">Login</Nav.Link>
      )
    }
  
  }

  const navDropdownTitle = (<span><AccountCircleIcon /> {props.username} </span>);

  return (
    <Navbar className="mainMenu" collapseOnSelect expand="lg" bg="gydme" variant="dark">
      <Navbar.Brand>
        <img width="30" src='/gydme-g-white.svg' alt="Gydme"></img> 
      </Navbar.Brand>
      
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {userLoggedInPresentation() }
        </Nav>

        <Nav id="collasible-nav-dropdown" style={{color:'#ffffff'}}>
            {navDropdownTitle}
        </Nav>

      </Navbar.Collapse>
    </Navbar>
  )}
export default MainMenuPresentation