import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { db } from "./../../firebase";
import { doc, deleteDoc } from "firebase/firestore";
import {convertTimestamp} from './../../js/utils';
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Chip from "@material-ui/core/Chip";
import { yellow, green } from "@material-ui/core/colors";
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';

const initialRows = [];

function getChipProps(params) {
  if (params.value === "pending") {
    return {
      icon: <WarningIcon style={{ fill: yellow[600] }} />,
      label: params.value,
      style: {
        borderColor: yellow[500]
      }
    };
  } else {
    return {
      icon: <CheckCircleIcon style={{ fill: green[500] }} />,
      label: params.value,
      style: {
        borderColor: green[500]
      }
    };
  }
}

export default function ListUsers(props) {
  const { companyCollectionUsers, getCompanyUsers } = props
  const [rows, setRows] = useState([]);
  const [removeSuccess, setRemoveSuccess] = useState('');
  const [fade, setFade] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
    let companyDocumentUserArray = [];
    if(companyCollectionUsers) {

      console.log('props.companyCollectionUsersCollection', companyCollectionUsers)
      if (companyCollectionUsers.length > 0) {
        // console.log('if props.companyCollectionUsersCollection', companyCollectionUsers)
        companyCollectionUsers?.map((user) => {
            // console.log(user)
            companyDocumentUserArray = [...companyDocumentUserArray, user];
          })
        }
      }

      if(props.companyInvitations) {
        // console.log('props.companyInvitations', props.companyInvitations)

        props.companyInvitations?.map((inviteUser) => {
          inviteUser.id = Math.floor(Math.random() * 10000)
          companyDocumentUserArray = [...companyDocumentUserArray, inviteUser];
        })
      }


      setRows(companyDocumentUserArray)
    
  }, [companyCollectionUsers, props.companyInvitations])


  
  
  const deleteUser = useCallback(
    (id, status, _id = null) => async () => {
      if (window.confirm("Are you sure you want to remove this user?") == true) {

        if(status === 'pending' && _id){
          await deleteDoc(doc(db, "invitations", _id));
        }
        if(status === 'active' ){
          await deleteDoc(doc(db, `companies/${props.userDetails.company}/users`, id));
          // db.doc(`companies/${props.userDetails.company}/users/${id}`).delete().then(function() {
            console.log('Deleted!');
            getCompanyUsers();
            setRemoveSuccess(`Removed user successfully`)
            setFade(true)
  
            const timer = setTimeout(() => {
              setRemoveSuccess('')
              setFade(false)
            }, 4000);
        }
      }
    },
    [rows],
  );

  const toggleAdmin = useCallback(
    (id) => () => {
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { ...row, isAdmin: !row.isAdmin } : row,
        ),
      );
    },
    [],
  );

  const columns = useMemo(
    () => [
      { field: 'userEmail', type: 'string', headerName: 'Email Address', flex: 1, minWidth:200, headerClassName: 'super-app-theme--header' },
      { 
        field: 'added', 
        type: 'date', 
        headerName: 'Added on', 
        width: 180, 
        valueFormatter: (params) => {
          const valueFormatted = params.value ? convertTimestamp(params.value) : ''
          return `${valueFormatted}`;
        },
      },
      { field: 'role', type: 'string', headerName: 'Role', flex:1, minWidth:120 },
      { field: 'status', type: 'string', headerName: 'Status', width: 120, renderCell: (params) => {
        return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
      } },
      {
        field: 'actions',
        type: 'actions',
        width:120,
        headerName: 'Remove',
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={deleteUser(params.id, params.row.status, params.row._id)}
          />
        ],
      },
    ],
    [deleteUser],
  );

    return (
      <div className="admin_page__list_users" style={{ height: 470, width: '100%', marginBottom:'140px'}}>
        <h1 className="admin_page__page_title" style={{marginBottom:'20px'}}>{props.userDetails.company} Users</h1>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          initialState={{
            sorting: {
              sortModel: [{ field: 'userEmail', sort: 'asc' }],
            }
          }}
          // checkboxSelection
        />
        <Stack sx={{ width: '100%', marginTop:2 }} spacing={2}>
          { removeSuccess ? ( <Fade in={fade}><Alert severity="success">{removeSuccess}</Alert></Fade> ) : null }
       </Stack>
      </div>
    );
}
