import './bootstrap'
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import store from './app/store';
import { Provider } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { ThemeProvider } from '@mui/material/styles';
import { GydmeTheme } from './theme'

// import i18n (needs to be bundled ;)) 
import './i18n';

// Importing the Bootstrap CSS
import './App.css';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={GydmeTheme}>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<div className="pageLoading"><Spinner animation="border" role="status"></Spinner></div>}>
          <App />
        </Suspense>
      </BrowserRouter>
    </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
