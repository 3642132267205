import React, { useState } from "react";
import axios from "axios";
import { setUserId, login } from "./../../features/appSlice";
import { db, auth } from "./../../firebase";
import firebase from "firebase/compat/app";
import { useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useTheme, styled } from "@mui/material/styles";
import { default as MuiButton } from "@mui/material/Button";
import Box from "@mui/material/Box";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./InviteRegistrationForm.css";
import Alert from "@mui/material/Alert";
import { useSearchParams } from "react-router-dom";

function InviteRegistrationForm(props) {
  const [errorMessage, setError] = useState("");
  const [successMessage, setSuccess] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const emailParam = searchParams.get("email") ? searchParams.get("email") : "";
  const codeParam = searchParams.get("code") ? searchParams.get("code") : "";
  const companyParam = searchParams.get("company") ? searchParams.get("company") : "";
  const accountTypeParam = searchParams.get("type") ? searchParams.get("type") : "";

  const theme = useTheme();
  const dispatch = useDispatch();

  const signUp = (firstname, lastname, clinicname, code, email, password) => {
    const userData = {
      firstName: firstname,
      lastName: lastname,
      code: code,
      userEmail: email,
      password: password,
      // company: company
    };

    // if is a Clinic
    if (accountTypeParam === "Clinic") {
      userData.clinicName = clinicname;
    }
    //if is a Clinic Account Manager (actual person)
    else {
      userData.firstName = firstname;
      userData.lastName = lastname;
    }

    // Send to endpoint to validate whether the user email address and code provided is in the companies/companyName/invitations documents
    // params companyName, email address, code
    axios.post(`${process.env.REACT_APP_API_URL}/activateUser`, userData).then(
      (response) => {
        if (response.status === 200 && response.data.result === "success") {
          setSuccess(`Activated account successfully, please `);
        } else {
          setError(`There was an error activating the account.`);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    // auth.createUserWithEmailAndPassword(
    //   email,
    //   password
    // ).then(resp => {

    //   dispatch(setUserId({
    //     userId: resp.user.uid
    //   }))

    //   dispatch(login({
    //     userEmail: resp.user.email,
    //     id: resp.user.uid,
    //     role: 'HCP'
    //   }))

    //   //add the user to the firestore db to save additional info
    //   db.collection("users").doc(resp.user.uid).set({
    //     userEmail: resp.user.email,
    //     firstName: firstname,
    //     lastName: lastname,
    //     id: resp.user.uid,
    //     role: 'HCP',
    //     favorites: ['test'],
    //     timestamp: firebase.firestore.FieldValue.serverTimestamp()
    //   })

    // })
  };

  const commonSchema = Yup.object().shape({
    email: Yup.string().email("*Must be a valid email address").max(100, "*Email must be less than 100 characters").required("*Email is required"),

    code: Yup.string().min(5, "*Invite code is not correct").max(5, "*Invite code should be 5 digits").required("*Invite code is required"),

    password: Yup.string().min(6, "*Password should be at least 6 characters").max(25, "*Password must be less than 25 characters").required("*Password is required"),

    passwordConfirmation: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const userSchema = Yup.object().shape({
    firstname: Yup.string().max(30, "*First name must be less than 30 characters").required("*First name is required"),

    lastname: Yup.string().max(30, "*Last name must be less than 30 characters").required("*Last name is required"),
  });

  const clinicSchema = Yup.object().shape({
    clinicname: Yup.string().max(100, "*Clinic name must be less than 100 characters").required("*Clinic name is required"),
  });

  const validationSchema = accountTypeParam !== "Clinic" ? commonSchema.concat(userSchema) : commonSchema.concat(clinicSchema);

  const initialValues = { code: codeParam, email: emailParam, password: "" };

  // Schema for yup
  if (successMessage) {
    return (
      <div className="activateRegistrationForm">
        {successMessage ? (
          <div className="success-message-register">
            <Alert severity="success">
              {successMessage} <a href="/login">Login here</a>.
            </Alert>
          </div>
        ) : null}
      </div>
    );
  }
  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);
          const signUpResponse = signUp(values.firstname, values.lastname, values.clinicname, values.code, values.email, values.password);
          // Simulate submitting to database, shows us values submitted, resets form
          setTimeout(() => {
            //alert(JSON.stringify(values, null, 2));
            //resetForm();
            setSubmitting(false);
          }, 500);
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
          <Form className="activateRegistrationForm" noValidate onSubmit={handleSubmit}>
            <h2>Activate account</h2>
            {accountTypeParam !== "Clinic" ? (
              <Form.Group controlId="activateRegistrationForm">
                <Form.Row>
                  <Col>
                    <Form.Control type="text" placeholder="First name" name="firstname" value={values.firstname} onChange={handleChange} onBlur={handleBlur} className={touched.firstname && errors.firstname ? "error" : null} isValid={touched.firstname && !errors.firstname} isInvalid={errors.firstname} />
                    <Form.Control.Feedback type="invalid">{touched.firstname && errors.firstname ? <div className="error-message">{errors.firstname}</div> : null}</Form.Control.Feedback>
                  </Col>
                  <Col>
                    <Form.Control type="text" placeholder="Last name" name="lastname" onChange={handleChange} onBlur={handleBlur} value={values.lastname} className={touched.lastname && errors.lastname ? "error" : null} isValid={touched.lastname && !errors.lastname} isInvalid={errors.lastname} />
                    <Form.Control.Feedback type="invalid">{touched.lastname && errors.lastname ? <div className="error-message">{errors.lastname}</div> : null}</Form.Control.Feedback>
                  </Col>
                </Form.Row>
              </Form.Group>
            ) : (
              <Form.Group controlId="formClinicName">
                <Form.Label>Clinic Name:</Form.Label>
                <Form.Control type="text" placeholder="Enter the Clinic Name" name="clinicname" onChange={handleChange} onBlur={handleBlur} value={values.clinicname} className={touched.clinicname && errors.clinicname ? "error" : null} isValid={touched.clinicname && !errors.clinicname} isInvalid={errors.clinicname} />
                <Form.Control.Feedback type="invalid">{touched.clinicname && errors.clinicname ? <div className="error-message">{errors.clinicname}</div> : null}</Form.Control.Feedback>
              </Form.Group>
            )}

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Code:</Form.Label>
              <Form.Control type="text" placeholder="Enter the invite code that was sent to you" name="code" onChange={handleChange} onBlur={handleBlur} value={values.code} className={touched.code && errors.code ? "error" : null} isValid={touched.code && !errors.code} isInvalid={errors.code} />
              <Form.Control.Feedback type="invalid">{touched.code && errors.code ? <div className="error-message">{errors.code}</div> : null}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address:</Form.Label>
              <Form.Control type="email" placeholder="Enter your email address" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} className={touched.email && errors.email ? "error" : null} isValid={touched.email && !errors.email} isInvalid={errors.email} />
              <Form.Control.Feedback type="invalid">{touched.email && errors.email ? <div className="error-message">{errors.email}</div> : null}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password:</Form.Label>
              <Form.Control type="password" placeholder="Password" name="password" onChange={handleChange} onBlur={handleBlur} value={values.password} className={touched.password && errors.password ? "error" : null} isValid={touched.password && !errors.password} isInvalid={errors.password} />
              <Form.Control.Feedback type="invalid">{touched.password && errors.password ? <div className="error-message">{errors.password}</div> : null}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Confirm password: </Form.Label>
              <Form.Control type="password" placeholder="Confirm password" name="passwordConfirmation" onChange={handleChange} onBlur={handleBlur} value={values.passwordConfirmation} className={touched.passwordConfirmation && errors.passwordConfirmation ? "error" : null} isValid={touched.passwordConfirmation && !errors.passwordConfirmation} isInvalid={errors.passwordConfirmation} />
              <Form.Control.Feedback type="invalid">{touched.passwordConfirmation && errors.passwordConfirmation ? <div className="error-message">{errors.passwordConfirmation}</div> : null}</Form.Control.Feedback>
            </Form.Group>

            <MuiButton className="activateRegistrationForm__button submit" type="submit" variant="contained" style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText, marginTop: "1em" }}>
              Activate
            </MuiButton>
            {errorMessage ? (
              <Alert sx={{ marginTop: "20px" }} severity="error">
                {errorMessage}
              </Alert>
            ) : null}
            {successMessage ? <div className="success-message-register">{successMessage}</div> : null}
          </Form>
        )}
      </Formik>
    </Container>
  );
}
export default InviteRegistrationForm;
