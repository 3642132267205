import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    channelId: null,
    userId: null,
    user: null,
    userDetails: null,
    loading:true
  },
  //actions below
  reducers: {
    enterChannel: (state, action) => {
      state.channelId = action.payload.channelId;
    },
    setUserId: (state, action) => {
      state.userId = action.payload.userId;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
    }
  },
});



export const { enterChannel, setUserId, setUserDetails, setLoading, login, logout } = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUser = (state) => state.app.user;

export const selectUserDetails = (state) => state.app.userDetails;

export const selectLoading = (state) => state.app.loading;

export const selectChannelId = state => state.app.channelId;

export const selectUserId = state => state.app.userId;

export default appSlice.reducer;
