import React, { useState, useEffect } from "react";
import styled from "styled-components";
import firebase from "firebase/compat/app";
import { auth, db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IconButton from "@mui/material/IconButton";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../features/appSlice";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

function EditPresentationButton(props) {
  const [user] = useAuthState(auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails, presentationID } = props;
  const userRef = doc(db, "users", user.uid);

  return (
    <IconButton className="editIcon hide" component={Link} to={`/edit-presentation/${presentationID}`}>
      <EditIcon color="primary" />
    </IconButton>
  );
}

export default EditPresentationButton;

const FavoriteContainer = styled.div`
  display: block;
`;
