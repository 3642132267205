import React, { useState, useEffect } from "react";
import axios from "axios";
import InviteSalesRep from "./InviteSalesRep";
import InviteHCP from "./InviteHCP";
import ListUsers from "./ListUsers";
import Container from "react-bootstrap/Container";
import { auth, db } from "./../../firebase";
import { useDocumentData, useCollectionData } from "react-firebase-hooks/firestore";
import { query, collection, doc, where } from "firebase/firestore";
import { default as MuiButton } from "@mui/material/Button";
import { useTheme, styled } from "@mui/material/styles";

export default function AdminInvite(props) {
  const { userDetails } = props;
  const [inviteType, setInviteType] = useState("");

  const companyQuery = query(doc(db, "companies", userDetails.company));

  const companyUsersQuery = query(collection(db, "companies", userDetails.company, "users"));
  //const [companyCollectionUsersCollection, companyCollectionUsersCollectionLoading] = useCollectionData(companyUsersQuery);
  const [companyCollectionUsers, setCompanyCollectionUsers] = useState([]);

  const companyInvitationQuery = query(collection(db, "invitations"), where("company", "==", userDetails.company));
  const [companyInvitations, companyInvitationsLoading] = useCollectionData(companyInvitationQuery, { idField: "id" });

  useEffect(() => {
    const fetchData = async () => {
      await getCompanyUsers();
    };
    fetchData();
  }, []); //This will run only once

  async function getCompanyUsers() {
    const userData = {
      company: userDetails.company,
      uid: userDetails.id,
    };
    const token = await auth.currentUser.getIdToken();
    let axiosConfig = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    const data = axios.post(`${process.env.REACT_APP_API_URL}/admin/getCompanyUsers`, userData, axiosConfig).then(
      (response) => {
        //console.log(response);
        if (response.status === 200) {
          setCompanyCollectionUsers(response.data);
          return response.data;
        }
      },
      (error) => {
        console.log(error);
      }
    );
    return data;
  }

  const showInviteForm = (e) => {
    if (e.target.value === "clinic") {
      setInviteType("clinic");
    } else if (e.target.value === "clinic_account_manager") {
      setInviteType("clinic_account_manager");
    }
  };

  return (
    <div className="page-wrapper">
      <div className="admin_page">
        <Container style={{ marginTop: "2rem" }}>
          <h1 className="dashboard__page_title">Invite a {userDetails.company} User</h1>
          <p>Invite a new or existing gydme account to {userDetails.company}. Get started by choosing a user type below: </p>
          <div className="invite_user_type_button_wrapper">
            <MuiButton value="clinic_account_manager" className={inviteType === "clinic_account_manager" ? "invite_user_type__button active" : "invite_user_type__button"} onClick={(e) => showInviteForm(e)} variant={inviteType === "clinic_account_manager" ? "contained" : "outlined"}>
              Clinic Account Manager
            </MuiButton>
            <MuiButton value="clinic" className={inviteType === "clinic" ? "invite_user_type__button active" : "invite_user_type__button"} onClick={(e) => showInviteForm(e)} variant={inviteType === "clinic" ? "contained" : "outlined"}>
              Clinic
            </MuiButton>
          </div>
          <div className="admin_page__invite_options">
            {inviteType === "clinic" && <InviteHCP getCompanyUsers={getCompanyUsers} company={userDetails.company} companyCollectionUsers={companyCollectionUsers} userDetails={userDetails} companyInvitations={companyInvitations} />}
            {inviteType === "clinic_account_manager" && <InviteSalesRep getCompanyUsers={getCompanyUsers} company={userDetails.company} companyCollectionUsers={companyCollectionUsers} userDetails={userDetails} companyInvitations={companyInvitations} />}
          </div>
          <ListUsers getCompanyUsers={getCompanyUsers} companyCollectionUsers={companyCollectionUsers} userDetails={userDetails} companyInvitations={companyInvitations} />
        </Container>
      </div>
    </div>
  );
}
