import React from 'react'
import MainMenuUnAuth from './../components/MainMenuUnAuth';
import HcpFooter from './../components/HcpFooter';
import RegistrationForm from './../components/Register/RegistrationForm';
import { useDispatch } from 'react-redux' 
import { setUserId, login } from './../features/appSlice'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import "./Register.css";
import { useTranslation } from 'react-i18next';



function Register(props) {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();
  
  if(props.user) {
    const path = `/dashboard`
    props.history.push({ 
      pathname: path,
    });
  }
        

    return (
      <div className="registrationScreen">
        <MainMenuUnAuth />
        <Container fluid={true} className="registrationScreen__container">
          <Row>
            <Col xs={12} sm={12} className="registrationScreen__formWrapper">
              <RegistrationForm props={props}/>
            </Col>
          </Row>
        </Container>

        <HcpFooter />
      </div>
    )
}

export default Register
