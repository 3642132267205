import React from 'react'
import MainMenu from './../components/MainMenu';
import HcpFooter from './../components/HcpFooter';
import EditPresentationForm from './../components/EditAdmin/EditPresentationForm';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useParams } from "react-router-dom";

function EditPresentation(props) {
  let { presentationId } = useParams();

    return (
      <div className="editPresentationScreen">
        <MainMenu username={props.user.email} userDetails={props.userDetails}/>
        <Container fluid={true} className="editPresentationScreen__container">
          <Row>
            <Col xs={12} sm={12} className="editPresentationScreen__formWrapper">
              <EditPresentationForm {...props} presentationId={presentationId}/>
            </Col>
          </Row>
        </Container>

        <HcpFooter />
      </div>
    )
}

export default EditPresentation
