import React from "react";
import "./PresentationUsers.css";
import Badge from 'react-bootstrap/Badge'

export default ({ presentationUsers }) => (
  <ul className="list-group presentationUsersWrapper">
      <li className="list-group-item" key='users'>Users viewing presentation:
        {presentationUsers.map((presentationUser, index) => {
          return (
            <Badge variant="success" key={presentationUser}>{presentationUser}</Badge>
          );
        })}
    </li>
  </ul>
);
