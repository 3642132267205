import React, { useState, useEffect } from "react";
import styled from "styled-components";
import firebase from "firebase/compat/app";
import { auth, db } from "./../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IconButton from "@mui/material/IconButton";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { setUserDetails } from "./../../features/appSlice";

function FavoritePresentation(props) {
  const [user] = useAuthState(auth);
  const dispatch = useDispatch();
  const { userDetails } = props;
  const userRef = doc(db, "users", user.uid);

  const handleFavorite = async (presentationID, favorited) => {
    let newFavorites = userDetails.favorites;

    //on click of favorite button -> add presentation ID to firestore record if it doesnt exist
    if (favorited) {
      newFavorites = userDetails.favorites.filter((item) => item !== presentationID);

      await updateDoc(userRef, {
        favorites: firebase.firestore.FieldValue.arrayRemove(presentationID),
      });

      dispatch(
        setUserDetails({
          userEmail: userDetails.userEmail,
          id: userDetails.id,
          role: userDetails.role,
          company: userDetails.company,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          clinicName: userDetails?.clinicName,
          province: userDetails?.province,
          license_number: userDetails?.license_number,
          favorites: newFavorites,
        })
      );
    } else {
      await updateDoc(userRef, {
        favorites: firebase.firestore.FieldValue.arrayUnion(presentationID),
      });

      const updatedFavorites = [...newFavorites, presentationID];

      dispatch(
        setUserDetails({
          userEmail: userDetails.userEmail,
          id: userDetails.id,
          role: userDetails.role,
          company: userDetails.company,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          clinicName: userDetails?.clinicName,
          province: userDetails?.province,
          license_number: userDetails?.license_number,
          favorites: updatedFavorites,
        })
      );
    }
  };

  return (
    <FavoriteContainer key={props.presentationID}>
      {userDetails && userDetails.favorites && (
        <IconButton aria-label="add to favorites" color={userDetails.favorites && userDetails.favorites.indexOf(props.presentationID) > 0 ? "red" : "primary"} onClick={() => handleFavorite(props.presentationID, userDetails.favorites.includes(props.presentationID))}>
          {userDetails.favorites.includes(props.presentationID) ? <FavoriteIcon key={props.presentationID} color="red" /> : <FavoriteBorderIcon key={props.presentationID} color="primary" />}
        </IconButton>
      )}
    </FavoriteContainer>
  );
}

export default FavoritePresentation;

const FavoriteContainer = styled.div`
  display: block;
`;
