import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { auth } from "./../../firebase"
import {default as MuiButton} from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal'
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import TabPanel from '@mui/lab/TabPanel';
import QRCode from 'qrcode.react';
import { useSelector } from "react-redux"
import { selectUser } from "./../../features/appSlice"


function PresentationInviteModal(props) {
  const [presentation, setPresentation] = useState(props.presentation);
  const [presentationId, setPresentationId] = useState(props.presentationId);
  const [text, setText] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [smsResponse, setSMSResponse] = useState('');
  const [smsResponseError, setSMSResponseError] = useState('');
  const [copyResponse, setCopyResponse] = useState('');
  const [username, setUsername] = useState('');
  const [tab, setTab] = useState("0");
  const user = useSelector(selectUser)


  // Send the SMS to the patient using Twilio
  const sendSMS = async (e) => {
    const token = await auth.currentUser.getIdToken();
    let axiosConfig = {
      headers: {
        authorization: `Bearer ${token}`
      }
    };

    const payload = {
      to: phoneNumber,
      body: `${window.location.origin}/?presentationId=${presentationId}`
    };

    axios.post(`${process.env.REACT_APP_API_URL}/sms`, payload, axiosConfig).then((response) => {
      // console.log(response.data.message);
      setSMSResponse({message: response.data.message, success:response.data.success})
    }, (error) => {
      console.log(error);
      setSMSResponseError(error)
    });
  }

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value)
  }

  function handleChangeTab(event, newValue) {
    setTab(newValue);
  }

  // Copy to clipboard (only works on https for IOS)
  const copyToClipBoard = () => {

    const copyText = window.location.origin+'?presentationId='+presentationId;
    const copySuccessText = 'Copied to clipboard.';
    const copyErrorText = 'Could not copy to clipboard.';

    if (navigator.clipboard != undefined) {
      // Chrome
      navigator.clipboard.writeText(copyText).then(() => {
        setCopyResponse({message: copySuccessText, success:true})
      })
      .catch(() => {
        setCopyResponse({message: copyErrorText, success:false})
      });
    }
    else if(window.clipboardData) { 
      // Internet Explorer
      window.clipboardData.setData("Text", copyText);
      setCopyResponse({message: copySuccessText, success:true})
    }
    else {
      setCopyResponse({message: copyErrorText, success:false})
    }
  
  }

  return (     
    <section>
      <Modal className="hcpPresentation__invite_patient" show={props.showQRCode} onHide={props.handleToggleQR}>
        <Modal.Header closeButton>
          <Modal.Title>Invite patient</Modal.Title>
        </Modal.Header>
        <Modal.Body>  
        <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: 'divider' }}>
            <TabList 
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="primary"
            aria-label="secondary tabs example"
            centered
            >
            
            <Tab sx={{fontSize:'16px'}} label="QR Code" value="0"/>
            <Tab sx={{fontSize:'16px'}} label="Link" value="1" />
            <Tab sx={{fontSize:'16px'}} label="SMS" value="2" />
            </TabList>
            </Box>
            <TabPanel value="0">
                <div className="qrCodeWrapper">
                <QRCode size={275} value={`${window.location.origin}/?presentationId=${presentationId}`} />
                </div>
            </TabPanel>
            <TabPanel value="1">
              <Grid item container spacing={1} alignContent="center">
                <Grid item xs="auto" sm={9} xs={12} alignContent="center">
                  <TextField 
                    fullWidth
                    id="outlined-read-only-input"
                    label="URL"
                    InputProps={{
                      readOnly: true,
                    }}
                    defaultValue={window.location.origin+'?presentationId='+presentationId}
                  />
                </Grid>
                <Grid item xs="auto" sm={3} xs={12} alignItems="flex-end">
                  <MuiButton sx={{height:'100%', width:'100%'}} color="secondary" variant="contained" size="large" onClick={copyToClipBoard}>Copy</MuiButton>
                </Grid>
              </Grid>
              <Box sx={{marginTop:'10px'}}>
                { copyResponse.success === true ?
                <Alert severity="success">{copyResponse.message}</Alert>
                :
                copyResponse.success === false && 
                <Alert severity="error">{copyResponse.message}</Alert>
                }
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Grid item container spacing={1} alignContent="center">
                <Grid item xs="auto" sm={9} xs={12} alignContent="center">
                  <TextField 
                  value={phoneNumber} 
                  onChange={(evt, value) => handleChangePhoneNumber(evt, value)} 
                  fullWidth 
                  id="outlined-basic" 
                  label="Phone Number" 
                  variant="outlined" 
                  helperText="Please enter the patients phone number"
                  />
                </Grid>
                <Grid item xs="auto" sm={3} xs={12} alignItems="flex-end">
                  <MuiButton sx={{height:'72%', width:'100%'}} color="secondary" variant="contained" size="large" onClick={sendSMS}>Send SMS</MuiButton>
                </Grid>
              </Grid>
              <Box sx={{marginTop:'10px'}}>
                { smsResponse.success === true ?
                <Alert severity="success">{smsResponse.message}</Alert>
                :
                smsResponse.success === false && 
                <Alert severity="error">{smsResponse.message}</Alert>
                }
              </Box>
            </TabPanel>
          </TabContext>
        </Modal.Body>
        <Modal.Footer>
            <span className="hcpPresentation__pin_code"><strong>Pin Code:</strong> {presentationId}</span><br/> 
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default PresentationInviteModal;