import { createTheme } from '@mui/material/styles';
export const GydmeTheme = createTheme({
    "palette":{
      "common":{
        "black":"rgba(51, 51, 51, 1)",
        "white":"#fff"
      },
      "background":{
        "paper":"#fff",
        "default":"rgba(250, 250, 250, 1)"
      },
      "primary":{
        "light":"rgba(92, 92, 92, 1)",
        "main":"rgba(51, 51, 51, 1)",
        "dark":"rgba(12, 12, 12, 1)",
        "contrastText":"#fff"
      },
      "secondary":{
        "light":"rgba(26, 189, 197, 1)",
        "main":"rgba(17, 143, 150, 1)",
        "dark":"rgba(11, 100, 105, 1)",
        "contrastText":"#fff"
      },
      "red":{
        "main":"#f44336",
        "contrastText":"#fff"
      },
      "error":{
        "light":"#e57373",
        "main":"#f44336",
        "dark":"#d32f2f",
        "contrastText":"#fff"
      },
      "text":{
        "primary":"rgba(0, 0, 0, 0.87)",
        "secondary":"rgba(0, 0, 0, 0.54)",
        "disabled":"rgba(0, 0, 0, 0.38)",
        "hint":"rgba(0, 0, 0, 0.38)"
      }
    }
  });
export default GydmeTheme;