import React from 'react'
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

export default function HcpFooter() {
  return (
    <div className="hcpFooterWrapper">
      <Grid container spacing={0}>
        
        <Grid item sm={6} xs={4} sx={{display: 'flex'}}>
            <img
              alt="Gydme"
              src="/gydme-full-2-turq.svg"
              width="80"
              className="d-inline-block align-top"
            />
        </Grid>

        <Grid item sm={6} xs={8} sx={{textAlign:'right'}}>
          <Grid className="footer-address">
            <p>12 James Street North, Suite 302<br/>Hamilton, ON L8R 2J9 </p>
            <p><span><a href="https://gyd.me/privacy-policy" target="_blank" className="link">Privacy Policy</a></span><span style={{marginLeft:"10px"}}><a href="https://gyd.me/terms-of-service/" target="_blank" className="link">Terms of Service</a></span></p>
          </Grid>
        </Grid>

      </Grid>
    </div>
  )
}
