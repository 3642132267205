import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Pusher from "pusher-js";
// import Pusher from 'pusher-js/with-encryption';
import Presentation from "./../Presentation";

export const PatientPresentation = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userCount, setUserCount] = useState(0);
  const [presentationId, setPresentationId] = useState(location.state.presentationId);
  const [presentation, setPresentation] = useState(location.state.presentation);
  const [slide, setSlide] = useState(0);
  const [allowed, setAllowed] = useState(false);
  const [patientEmail, setPatientEmail] = useState(location.state.emailAddress);
  const [presentationLanguage, setPresentationLanguage] = useState(location.state.presentationLanguage);

  useEffect(() => {
    requestPermission(patientEmail);

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: "us2",
      encrypted: true,
      authEndpoint: `${process.env.REACT_APP_API_URL}/auth`,
      auth: {
        params: { username: patientEmail },
      },
    });

    // Subscribe to pusher channel for presentation
    const presentation_channel = pusher.subscribe(presentationId);

    // console.log('presentation_channel', presentation_channel)

    // When access is granted
    presentation_channel.bind("grantAccess", (data) => {
      if (data.requestedAccessUsername === patientEmail) {
        setAllowed(true);
      }
    });

    // when access is denied for this user
    presentation_channel.bind("denyAccess", (data) => {
      if (data.requestedAccessUsername === patientEmail) {
        pusher.unsubscribe(presentationId);
        navigate("/");
        setAllowed(false);
      }
    });

    // Listen for slide changes on channel
    presentation_channel.bind("slide", (data) => {
      setSlide(data.slide);
    });

    // When Subscription to presence channel is succeeded
    presentation_channel.bind("pusher:subscription_succeeded", (member) => {
      // var me = presentation_channel.members.me;
      // var userInfo = me.info;
      // setUserCount(presentation_channel.members.count);
      //   const memberArray = [];
      //   Object.values(presentation_channel.members.members).forEach(async function(member) {
      //     memberArray.push( member.name );
      //   })
      // console.log('presentationUser set state', memberArray)
    });

    // When a Member is added to the presence channel
    presentation_channel.bind("pusher:member_added", (member) => {
      // console.log('member added', member);
    });

    // When a member is removed from the presence channel
    presentation_channel.bind("pusher:member_removed", (member) => {
      // console.log('member removed:', member);
    });

    // Bind to the endMeeting event on presence channel
    presentation_channel.bind("endMeeting", (data) => {
      presentation_channel.unbind();
      pusher.unsubscribe(presentationId);
      pusher.disconnect();
      navigate("/");
    });

    //unmount
    return () => {
      presentation_channel.unbind();
      pusher.unsubscribe(presentationId);
      pusher.disconnect();
    };
  }, []);

  // Request Permission to presentation
  const requestPermission = (username) => {
    const payload = {
      username: username,
      presentationId: presentationId,
    };
    axios.post(`${process.env.REACT_APP_API_URL}/requestAccess`, payload);
  };

  const handleSelect = (selectedIndex, e) => {
    setSlide(selectedIndex);

    const payload = {
      username: patientEmail,
      slide: selectedIndex,
    };
    //presentationID will be past
    axios.post(`${process.env.REACT_APP_API_URL}/slide`, payload);
  };

  if (!allowed) {
    return (
      <div className="waiting-for-access">
        <div className="hcp_login_screen__gydme-logo">
          <img alt="Gydme" src="/gydme-full-2-turq.svg" width="150" className="d-inline-block align-top" />
        </div>
        <p>Please wait for the organizer to approve your access, thank you for your patience!</p>
        <div className="waiting-for-access__rotate-instructions">
          <p>Please rotate your device to landscape for the best experience</p>
          <img className="mobile-rotate-device" src="/rotate-device.png" alt="Rotate your device" />
        </div>
      </div>
    );
  } else {
    return (
      <div className="App patientPresentation">
        <header className="App-header"></header>

        <Presentation slide={slide} handleSelect={handleSelect} isPatient={true} presentation={presentation} presentationLanguage={presentationLanguage} />

        <section>
          <div className="container-fluid">
            <div className="row viewingPresentation">{/* <PresentationUsers presentationUsers={presentationUsers} /> */}</div>
          </div>
        </section>
      </div>
    );
  }
};

export default PatientPresentation;
