import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import { AiFillHome } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import { auth } from "./../firebase"

const MainMenuUnAuth = (props) => { 
  const { t, i18n } = useTranslation();

  const signOut = () => {
    auth.signOut();
  }

return (
<Navbar collapseOnSelect expand="lg" bg="gydme" variant="dark">
  <Navbar.Brand href="/">
    <img width="30" src='/gydme-g-white.svg'></img> 
    </Navbar.Brand>
  
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />

  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="/">
        Patient login
      </Nav.Link>
    </Nav>
  </Navbar.Collapse>

  <Navbar.Collapse className="justify-content-end">
    <Navbar.Text>
      <AccountCircleIcon style={{ fill: 'white' }}/> <a href="/login" className=""> Login</a> /<a href="/register" className=""> Register</a>
    </Navbar.Text>
    {/* <Navbar.Text className="languagePicker">
      <LanguageIcon style={{ color: 'white', fill: 'white' }}/> <a onClick={() => {i18n.changeLanguage("en")}} className=""> EN</a> /<a onClick={() => {i18n.changeLanguage("fr")}}  className=""> FR</a>
    </Navbar.Text> */}
  </Navbar.Collapse>

</Navbar>
)}
export default MainMenuUnAuth