import parse from 'html-react-parser';

/* Replace the registered trademark symbol with a superscript version */
export const replaceRegisteredTradeMark = (string) => {
  let new_str = string.replace('®', '<sup>®</sup>');
  new_str = parse(new_str);
  return new_str;
}

// mappings to get presentation slides
export const getPresentationSlides = async (presentation, isPatient, presentationLanguage) => {
  let data = '';
  
  //is this an HCP or patient view?
  if (isPatient === true){
    await import('./../presentations/'+presentation+'/patient/').then(slideData => {
      data = slideData[`patientSlides_${presentationLanguage}`]
    })
  }
  //is HCP
  else {
    await import('./../presentations/'+presentation+'/hcp/').then(slideData => {
      data = slideData[`hcpSlides_${presentationLanguage}`]
    })
  }
  return data;
}

export const isAdmin = (role) => {
  return role === 'ADMIN' ? true : false; 
}

export const isSalesRep = (role) => {
  return (role === 'Sales Rep' || role === 'Clinic Account Manager') ? true : false; 
}


export const convertTimestamp = (timestamp) => {
  let date;
  // console.log('timestamp',timestamp )
  if ('_seconds' in timestamp) {
    date = new Date(timestamp._seconds*1000)
  }
  else {
    date = timestamp.toDate();
  }
  var options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

	let mm = date.getMonth();
	let dd = date.getDate();
	let yyyy = date.getFullYear();
  var seconds = date.getSeconds();
  var minutes = date.getMinutes();
  var hour = date.getHours();
  var timeString = date.toLocaleString('en-US', options);

	date = mm + '/' + dd + '/' + yyyy + ' ' + timeString;
	return date;
}