import React from "react";
import Container from "react-bootstrap/Container";
import MainMenu from "./../components/MainMenu";
import EditAccountInformationForm from "./../components/AccountPreferences/EditAccountInformationForm";
import EditAccountInformationFormAdmin from "./../components/AccountPreferences/EditAccountInformationFormAdmin";
import EditAccountInformationFormClinic from "./../components/AccountPreferences/EditAccountInformationFormClinic";
import RequestAccountDeletion from "./../components/AccountPreferences/RequestAccountDeletion";
import HcpFooter from "./../components/HcpFooter";

function Preferences(props) {
  const { user, userDetails } = props;
  return (
    <div className="page-wrapper">
      <MainMenu username={user.email} userDetails={userDetails} />
      <div className="dashboard">
        <Container>
          <h1 className="dashboard__page_title">Account preferences</h1>
          <p>You can update your account information below or delete your account and all of its associated data.</p>
          {userDetails.role === "Clinic" && <EditAccountInformationFormClinic user={user} userDetails={userDetails} />}
          {userDetails.role === "HCP" && <EditAccountInformationForm user={user} userDetails={userDetails} />}
          {(userDetails.role === "ADMIN" || userDetails.role === "Clinic Account Manager") && <EditAccountInformationFormAdmin user={user} userDetails={userDetails} />}
          <br />
          <RequestAccountDeletion user={user} userDetails={userDetails} />

          {/* Contact support */}
        </Container>
      </div>
      <HcpFooter />
    </div>
  );
}

export default Preferences;
