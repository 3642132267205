import * as React from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Container from '@mui/material/Container';
import { useTheme, styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';


let actions = [
  { icon: <PersonAddAlt1Icon />, name: 'Invite Patient', operation: 'invite', tooltip: 'Invite Patient' },
  { icon: <VisibilityIcon />, name: 'Patient View', operation: 'show_patient_view', tooltip: 'Show Patient View' },
  { icon: <CloseIcon />, name: 'End Meeting', operation: 'end_meeting', tooltip: 'End Meeting' }
];

const FloatingActionButtons = (props) => {
  return (
    <Container maxWidth={false}>
      <Box sx={{ '& > :not(style)': { m: 1 } }}>
        <div className="slideNumbers"> 
          <Grid item container spacing={0} alignContent="center" alignItems="center">
            <Grid item xs="auto" xs={12} sm={3} alignContent="center"></Grid>
            <Grid item xs="auto" xs={12} sm={6} alignContent="center" justifyContent="center">
              { `${props.slide  + 1} / ${props.slideData.length}` }
            </Grid>
            <Grid item xs="auto" xs={12} sm={3} alignContent="center" justifyContent="flex-end" sx={{textAlign:'right'}}>
              {actions.map((action, index) => (
                <Tooltip key={action.operation} title={action.tooltip}>
                <CustomFab
                  className={'btn-'+action.operation}
                  size="medium" 
                  aria-label="add"
                  operation={action.operation}
                  onClick={(e) => {
                    handleClick(e,action.operation, props.handleToggleqr, props.togglePatientview, props.handleClosingAlertOpen)
                  }}
                >
                {action.icon}
                </CustomFab>
                </Tooltip>
              ))}
            </Grid>
          </Grid>
        </div>
      </Box>
    </Container>
  );
}

//handler function
let patientViewOn = false;
function handleClick(e, operation, handleToggleQR, togglePatientView, handleClosingAlertOpen){
  e.preventDefault();
  if(operation === 'invite'){
    handleToggleQR()
  }
  else if(operation === 'show_patient_view'){
    patientViewOn = !patientViewOn;

    if(patientViewOn){
      actions = [
        { icon: <PersonAddAlt1Icon />, name: 'Invite Patient', operation: 'invite', tooltip: 'Invite Patient' },
        { icon: <VisibilityOffIcon />, name: 'Patient View', operation: 'show_patient_view', tooltip: 'Hide Patient View' },
        { icon: <CloseIcon />, name: 'End Meeting', operation: 'end_meeting', tooltip: 'End Meeting' }
      ];
    }
    else {
      actions = [
        { icon: <PersonAddAlt1Icon />, name: 'Invite Patient', operation: 'invite', tooltip: 'Invite Patient' },
        { icon: <VisibilityIcon />, name: 'Patient View', operation: 'show_patient_view', tooltip: 'Show Patient View' },
        { icon: <CloseIcon />, name: 'End Meeting', operation: 'end_meeting', tooltip: 'End Meeting' }
      ];
    }
    
    togglePatientView()
  }
  else if(operation=='end_meeting'){
    handleClosingAlertOpen();
  }
  // setOpen(!open);// to close the speed dial, remove this line if not needed.
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `transparent`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? '#464646'
      : '#333333',
  flexDirection: 'row-reverse',
  borderBottom: '1px solid black',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    justifyContent: 'center',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor:'#464646'
}));

const CustomFab = styled(Fab,{shouldForwardProp: (props) => props !== 'operation'})(({ theme, operation }) => ({
  margin:'0px 5px',
  color:'#ffffff',
  backgroundColor:
    operation === 'end_meeting'
      ? theme.palette.error.main
      : theme.palette.secondary.main,
  '&:hover': {
    backgroundColor:
    operation === 'end_meeting'
      ? theme.palette.error.main
      : theme.palette.secondary.dark,
    },
}));


export default function PresentationFooterControls(props) {
  const [expanded, setExpanded] = React.useState('panel1');
  const theme = useTheme();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  return (
    <>
    <div className="slideNumbersSpeedDial">
      <FloatingActionButtons slide={props.slide} slideData={props.slideData} handleToggleqr={props.handleToggleQR} togglePatientview={props.togglePatientView} handleClosingAlertOpen={props.handleClosingAlertOpen}/>
    </div>
    <div className="slideInformation">    
      <Accordion onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <MuiAccordionSummary
            expandIcon={<ExpandMore sx={{ fontSize: '1.5rem', color:theme.palette.secondary.main }} />}
            {...props}
        />
        </AccordionSummary>
        <AccordionDetails>
        <ol className="carousel-indicators" id="carousel-indicators">
          {props.slideData.map((currentSlide, index) => {
            return (
              <>
              <li
                key={'thubmnail'+index}
                className={index === props.slide ? 'active' : null}
                onClick={(e) => props.handleSelect(index, e)}
                style={{ backgroundImage: `url(${currentSlide.src})`, backgroundSize:'cover' }}
              >
                <span>{index + 1}</span>
              </li>
              
              </>
            );
          })}
        </ol>
        </AccordionDetails>
      </Accordion>
    </div>
    </>
  );
}