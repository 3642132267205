import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import {default as MuiButton} from '@mui/material/Button';
import { auth } from './../../firebase'
import { signInWithEmailAndPassword, setPersistence, browserSessionPersistence} from "firebase/auth";
import { useFormik } from 'formik';
import ResetPassword from './ResetPassword';
import * as Yup from 'yup';
import "./LoginForm.css";


// Schema for yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
  .email("*Must be a valid email address")
  .max(100, "*Email must be less than 100 characters")
  .required("*Email is required"),

  password: Yup.string()
  .min(6, '*Password should be at least 6 characters')
  .max(100, "*Password must be less than 100 characters")
  .required("*Password is required")
});


export const LoginForm = ({signIn}) => {
  const [loginReset, setLoginReset] = useState('login');
  const [errorMessage, setError] = useState('');
  const [success, setSuccess] = useState(null);

  const onSubmit = async (values) => {
    //e.preventDefault();
    const { ...data } = values;
    try {
      await setPersistence(auth, browserSessionPersistence);
      await signInWithEmailAndPassword(auth, values.email, values.password)
      formik.resetForm();
      setError(null);
      setSuccess('true');
    } catch (error) {
        formik.setSubmitting(false)
        var errorCode = error.code;
        var errorMessage = error.message;
        
        switch(errorCode) {
          case 'auth/wrong-password':
            // code block
            setError('Invalid login credentials, please try again.');
            break;
          case 'auth/too-many-requests':
            // code block
            setError(error.message);
            break;
          default:
            setError('Invalid login credentials, please try again.');
            // code block
        }
    }

  };
  // Formik setup
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
    enableReinitialize:true
  });

  //switch 

  // Reset Password using Firebase Auth
  const sendPasswordResetEmail = async (email) => {
    try {
      await auth.sendPasswordResetEmail(email);
      alert("Password reset link sent!");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  return (
	  <Container>
    {loginReset === 'reset' ?
      <ResetPassword /> :
    <Form noValidate className="LoginForm" onSubmit={formik.handleSubmit}>
    <h2>Login</h2>
        <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address:</Form.Label>
            <Form.Control 
              type="email" 
              placeholder="Enter your email address" 
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.touched.email && formik.errors.email ? "error" : null}
              isValid={formik.touched.email && !formik.errors.email}
              isInvalid={formik.errors.email} />
            <Form.Control.Feedback type="invalid">
            {formik.touched.email && formik.errors.email ? (
              <div className="error-message">{formik.errors.email}</div>
            ): null}
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password:</Form.Label>
            <Form.Control 
              type="password" 
              placeholder="Password"
              name="password"
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur}
              value={formik.values.password}
              className={formik.touched.password && formik.errors.password ? "error" : null}
              isValid={formik.touched.password && !formik.errors.password}
              isInvalid={formik.errors.password}
              />
            <Form.Control.Feedback type="invalid">
              {formik.touched.password && formik.errors.password ? (
                <div className="error-message">{formik.errors.password}</div>
              ): null}
            </Form.Control.Feedback>
        </Form.Group>
        <Button type="submit" variant="dark w-100" disabled={formik.isSubmitting}>Login</Button>
        {errorMessage ? ( <div className="error-message-login">{errorMessage}</div> ): null}
        <div className="d-flex justify-content-end align-items-center mt-2">
          <MuiButton color="secondary" onClick={() => setLoginReset('reset')}>Reset your password</MuiButton>
          / 
          <MuiButton href='/register' color="secondary">Register</MuiButton>
        </div>
      </Form>

      }
    </Container>
  );
}
export default LoginForm;