import React, { useEffect } from 'react'
import MainMenuUnAuth from './../components/MainMenuUnAuth';
import LoginForm from './../components/Login/LoginForm';
import HcpFooter from './../components/HcpFooter';
import { useDispatch } from "react-redux";
import { auth } from "./../firebase";
import { useNavigate } from "react-router"

function Login(props) {  
  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // user is logged in
          // redirect
          navigate('/dashboard');
      } else {
        // user is logged out
        // dispatch(logout);
      }
    });
  }, []);


  return (
    <>
    <div className="HcpLogin">
      <MainMenuUnAuth />
      <section>
      <div className="hcp_login_screen__gydme-logo">
        <img alt="Gydme" src="/gydme-full-2-turq.svg" width="150" className="d-inline-block align-top"/>
      </div>
          <LoginForm />
      </section>
      
    </div>
    <HcpFooter />
    </>
  )
}

export default Login
