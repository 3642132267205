import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { default as MuiButton } from "@mui/material/Button";
import { useCollectionOnce, useDocument } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LaunchIcon from "@mui/icons-material/Launch";
import FavoritePresentation from "./FavoritePresentation";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { replaceRegisteredTradeMark } from "./../../js/utils";
import { auth, db } from "./../../firebase";
import firebase from "firebase/compat/app";
import ChoosePresentationLanguage from "./ChoosePresentationLanguage";
import { collection, query, where, doc, setDoc, getDocs } from "firebase/firestore";
import EditPresentationButton from "./EditPresentationButton";

function DashboardListing(props) {
  const navigate = useNavigate();
  const { user, userDetails } = props;
  const defaultCompany = "None";
  const { company = defaultCompany, role = "HCP" } = userDetails;

  const [allPresentations, setAllPresentations] = useState([]);
  const [companyArray, setCompanyArray] = useState([]);
  const [allPresentationsSorted, setAllPresentationsSorted] = useState([]);

  const [presentations, presentationsLoading] = useCollectionOnce(query(collection(db, "presentations"), where("presentationType", "==", "public")));
  const [userCompanies, userCompaniesLoading] = useCollectionOnce(collection(db, "users", user.uid, "companies"));

  const [privatePresentations, privatePresentationsLoading] = useCollectionOnce(companyArray.length > 0 ? query(collection(db, "presentations"), where("presentationType", "==", "private"), where("company", "in", companyArray)) : null);

  const [presentationLanguage, setPresentationLanguage] = React.useState([
    {
      rybelsus: "English",
      "trikafta-12": "English",
      "trikafta-6-11": "English",
      quinsair: "English",
    },
    {
      "trikafta-12": "English",
    },
    {
      "trikafta-6-11": "English",
    },
    {
      quinsair: "English",
    },
  ]);

  useEffect(() => {
    async function getGyds() {
      const companiesArray = [];
      if (!userCompaniesLoading) {
        userCompanies.docs.map((privateDoc) => {
          companiesArray.push(privateDoc.id);
        });
        setCompanyArray(companiesArray);

        if (userCompanies.docs.length > 0) {
          //get all company specific gyds and also public gyds - being a user in a company does not limit you now to just those gyds
          const companyGyds = await getCompanyGyds(companiesArray);
          const publicDocs = await getPublicGyds();
          setAllPresentations([...companyGyds, ...publicDocs]);
        } else if (userDetails.testing === true) {
          const testingDocs = await getTestingGyds();
          setAllPresentations(testingDocs);
        } else {
          const publicDocs = await getPublicGyds();
          setAllPresentations(publicDocs);
        }
      }
    }
    getGyds();
  }, [userCompaniesLoading]);

  // Get all company gyds
  const getCompanyGyds = async (companiesArray) => {
    let companyPrivateGyds = [];
    const privateCompanyGyds = query(collection(db, "presentations"), where("presentationType", "==", "private"), where("company", "in", companiesArray));

    const privateGydsSnapshot = await getDocs(privateCompanyGyds);

    privateGydsSnapshot.docs.map((privateDoc) => {
      companyPrivateGyds.push(privateDoc.data());
    });
    return companyPrivateGyds;
  };

  // Get all public gyds
  const getPublicGyds = async () => {
    let tempPublicDocs = [];
    const publicGyds = query(collection(db, "presentations"), where("presentationType", "==", "public"));
    const publicGydsSnapshot = await getDocs(publicGyds);
    publicGydsSnapshot.docs.map((publicDoc) => {
      tempPublicDocs.push(publicDoc.data());
    });
    return tempPublicDocs;
  };

  const getTestingGyds = async () => {
    let tempTestingDocs = [];
    const testingGyds = query(collection(db, "presentations"), where("presentationType", "==", "testing"));
    const testingGydsSnapshot = await getDocs(testingGyds);
    testingGydsSnapshot.docs.map((testingDoc) => {
      tempTestingDocs.push(testingDoc.data());
    });
    return tempTestingDocs;
  };

  // useEffect(() => {
  //   console.log(allPresentations);
  //   // Define a couple of sorting callback functions, one with hardcoded sort key and the other with an argument sort key
  //   const sortByType = (a, b) => (a.presentationType.toLowerCase() > b.presentationType.toLowerCase() ? 1 : -1);

  //   setAllPresentationsSorted(allPresentations.sort(sortByType));
  // }, [allPresentations]);

  const launchPresentation = async (presentation, presentationName, sendFollowUpEmail, index) => {
    const presentationId = user.uid + presentation + "-" + Math.floor(Math.random() * 100 + 1);
    const presentationLaunchLanguage = presentationLanguage[0][presentation];
    // console.log('launch presentationLanguage', presentationLanguage[index][presentation])

    //add new activePresentation
    if (user) {
      await setDoc(doc(db, "activePresentations", presentationId), {
        ownerID: user.uid,
        presentationID: presentationId,
        originalPresentationID: presentation,
        presentationName: presentationName,
        slide: 0,
        presentationLanguage: presentationLaunchLanguage,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      await setDoc(doc(db, "requestAccess", presentationId), {
        ownerID: user.uid,
        presentationID: presentationId,
        presentation: presentation,
        presentationLanguage: presentationLaunchLanguage,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }

    const path = `/hcp/presentation/${presentationId}`;

    // console.log('presentationId', presentationId)
    // console.log('presentation', presentation)
    // console.log('presentationName', presentationName)
    // console.log('presentationLaunchLanguage', presentationLaunchLanguage)
    // console.log('sendFollowUpEmail', sendFollowUpEmail)
    navigate(path, {
      state: {
        presentationId: presentationId,
        username: user.email,
        presentation: presentation,
        presentationName: presentationName,
        presentationLanguage: presentationLaunchLanguage,
        sendFollowUpEmail: sendFollowUpEmail,
      },
    });
  };

  const handlePresentationLanguageChange = (event, presentationID, index) => {
    let newArr = [...presentationLanguage]; // copying the old datas array
    newArr[0][presentationID] = event.target.value;
    setPresentationLanguage(newArr);
  };

  return (
    <PresentationContainer>
      {allPresentations.length > 0 &&
        allPresentations?.map((doc, index) => (
          <PresentationItem key={doc.id}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardMedia
                // height="155"
                component="img"
                image={doc.presentationThumbnail}
                alt={doc.presentationName}
              />
              <CardContent
                sx={{
                  borderTop: "1px solid #E3E3E3",
                  pb: 1,
                  pt: 1,
                  flex: "1 1 auto",
                }}
              >
                <Grid item container spacing={0} alignContent="center" alignItems="baseline" sx={{ pb: 1 }}>
                  <Grid item xs={10} sx={{ paddingRight: "10px;" }} alignContent="center">
                    <Typography variant="h6" component="div">
                      <Box sx={{ fontWeight: "bold", lineHeight: "1.2em" }}>{replaceRegisteredTradeMark(doc.presentationName)}</Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className="card-icons" justify="flex-end" alignContent="flex-end" sx={{ textAlign: "right" }}>
                    <FavoritePresentation key={`${doc.id}-favourite`} presentationID={doc.id} userDetails={userDetails} />

                    {doc.ownerID && doc.ownerID.includes(user.uid) && <EditPresentationButton key={doc.id} presentationID={doc.id} userDetails={userDetails} />}
                  </Grid>
                </Grid>
                <Typography variant="body1" color="primary" sx={{ pt: 0.5 }}>
                  {replaceRegisteredTradeMark(doc.presentationDescription)}
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing={true}
                sx={{
                  p: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    padding: "0px",
                    width: "100%",
                  }}
                >
                  <ChoosePresentationLanguage languages={doc.languages} presentationID={doc.presentationID} handlePresentationLanguageChange={handlePresentationLanguageChange} presentationLanguage={presentationLanguage} index={index} />
                  <MuiButton className="btn-launch-presentation" onClick={() => launchPresentation(doc.presentationID, doc.presentationName, doc.sendFollowUpEmail, index)} color="secondary" size="large" sx={{ p: 2 }}>
                    <span>Start Presentation</span>
                    <LaunchIcon sx={{ fontSize: 20, marginLeft: "5px" }} className="valign-txt-bottom" />
                  </MuiButton>
                </div>
              </CardActions>
            </Card>
          </PresentationItem>
        ))}
    </PresentationContainer>
  );
}

export default DashboardListing;

const PresentationContainer = styled.div`
  display: block;
  > hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #49274b;
  }
  @media only screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const PresentationItem = styled.div`
  padding: 0px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  max-width: 100%;
  padding-right: 20px;
  padding-bottom: 20px;

  &:first-child {
    padding-left: 0px;
  }

  > hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #49274b;
  }
  @media only screen and (min-width: 768px) {
    color: #000000;
    width: 50%;
    margin-top: 0px;
  }
  @media only screen and (min-width: 1024px) {
    color: #000000;
    margin-top: 0px;
    width: 50%;
  }
  @media only screen and (min-width: 1200px) {
    width: 50%;
  }
`;
