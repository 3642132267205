import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { auth, db } from "./../firebase";
import { useNavigate } from "react-router-dom";
import { isAdmin, isSalesRep } from "./../js/utils";

const MainMenu = (props) => {
  const { userDetails, username, user } = props;

  let navigate = useNavigate();

  // Sign Out the user
  const signOut = () => {
    auth.signOut();
    navigate("/login");
  };

  const goToPreferences = () => {
    const path = `/preferences`;
    navigate({
      pathname: path,
      state: {
        username: props.user,
      },
    });
  };

  const InPresentationLinks = (props) => {
    if (props.isAdmin) {
      return (
        <>
          <Nav.Link href="/admin" className="admin_link">
            <SupervisorAccountIcon className="mainMenu__home-icon" />
            <span>Admin</span>
          </Nav.Link>
        </>
      );
    }
    if (props.isSalesRep) {
      return (
        <Nav.Link href="/clinic-account-manager" className="dashboard_link">
          <SupervisorAccountIcon className="mainMenu__home-icon" />
          Invite Clinic
        </Nav.Link>
      );
    } else {
      return <></>;
    }
  };

  // inPresentation: boolean
  // const loggedInLinks = (inPresentation) => {
  //     links =
  //       { inPresentation &&
  //         <Nav.Link onClick={() => props.handleClosingAlertOpen()} className="dashboard_link">
  //           <DashboardIcon className="mainMenu__home-icon"/>Dashboard
  //         </Nav.Link>
  //       }

  //       // Admin Links
  //       {!userDetailsLoading && isAdmin(userDetails.role) &&
  //         <Nav.Link onClick={() => props.handleClosingAlertOpen()} className="dashboard_link">
  //           <DashboardIcon className="mainMenu__home-icon"/>Dashboard
  //         </Nav.Link>
  //       }
  // }
  // const loggedInNavLinks = ();

  const userLoggedInPresentation = () => {
    // Logged in and in a presentation
    if (username && userDetails) {
      return <InPresentationLinks isAdmin={isAdmin(userDetails.role)} isSalesRep={isSalesRep(userDetails.role)} />;
    }
    // Not logged in
    else {
      return <Nav.Link href="/">Login</Nav.Link>;
    }
  };

  const navDropdownTitle = (
    <span>
      <AccountCircleIcon /> {username}
    </span>
  );

  return (
    <Navbar className="mainMenu" collapseOnSelect expand="lg" bg="gydme" variant="dark">
      <Navbar.Brand href="/dashboard">
        <img width="30" src="/gydme-g-white.svg" alt="Gydme"></img>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/dashboard" className="dashboard_link">
            <DashboardIcon className="mainMenu__home-icon" />
            Dashboard
          </Nav.Link>
          {userLoggedInPresentation()}
        </Nav>

        <NavDropdown title={navDropdownTitle} id="collasible-nav-dropdown">
          <NavDropdown.Item onClick={goToPreferences}>Account preferences</NavDropdown.Item>
          <NavDropdown.Item onClick={signOut}>Signout</NavDropdown.Item>
        </NavDropdown>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default MainMenu;
