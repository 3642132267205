import React from 'react';
import Container from 'react-bootstrap/Container'
import MainMenu from './../components/MainMenu';
import HcpFooter from './../components/HcpFooter';
import DashboardListing from "./../components/Dashboard/DashboardListing";

function Dashboard(props) {
  const { user, userDetails } = props
    
  return (
    <div className="page-wrapper">
      <MainMenu username={user.email} userDetails={userDetails}/>
      <div className="dashboard">
        <Container>
          <h1 className="dashboard__page_title">Dashboard</h1>
          <p>Select a presentation below to start patient counselling: </p>
          <DashboardListing user={user} userDetails={userDetails} />
        </Container>
      </div>
      <HcpFooter/>
    </div>
  );
}

export default Dashboard;