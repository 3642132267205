import React, { useState } from 'react'
import firebase from "firebase/compat/app";
import { useDispatch } from 'react-redux' 
import styled from "styled-components";
import { db, auth } from './../../firebase'
import { doc, deleteDoc } from "firebase/firestore";
import { deleteUser, reauthenticateWithCredential } from "firebase/auth";
import Form from 'react-bootstrap/Form'
import {default as MuiButton} from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function RequestAccountDeletion(props){
  const { user, userDetails } = props
  const userId = user.uid;

  const userEmail = userDetails?.userEmail;

  const [errorMessage, setError] = useState('');
  const [successMessage, setSuccess] = useState('');
  const dispatch = useDispatch()


  const updateAccount = async (email, password) => {
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password
    );

    // Prompt the user to re-provide their sign-in credentials
    reauthenticateWithCredential(auth.currentUser, credential).then(async function() {
      // User re-authenticated.
      const confirmModal = window.confirm('Are you sure you want to delete your account and all associated account data? This action cannot be undone.');
      if (confirmModal) {
        // Delete user firestore doc 
        await deleteDoc(doc(db, "users", userId));

        // Delete auth user 
        deleteUser(auth.currentUser).then(() => {
          // User deleted.
          props.history.push({ 
            pathname: '/login',
         });
        }).catch((error) => {
          // An error ocurred
          // ...
        });
      }
    }).catch(function(error) {
      let firebaseErrorCode = error.code;
      let firebaseErrorMessage = error.message;
      let errorMessage = '';
      console.log(firebaseErrorCode)
      switch (firebaseErrorCode.substr(5)) {
        
        case 'ERROR_WRONG_PASSWORD':
        case 'wrong-password':
          errorMessage = 'Incorrect email/password combination.';
          break;
        case 'ERROR_USER_NOT_FOUND':
        case 'user-not-found':
          errorMessage = 'No user found with this email address.';
          break;
        case 'ERROR_USER_DISABLED':
        case 'user-disabled':
          errorMessage = 'This user has been disabled.';
          break;
        case 'TOO_MANY_ATTEMPTS_TRY_LATER':
        case 'too-many-requests':
          errorMessage = 'Too many failed authentication attempts. Please try again later.';
          break;
        case 'ERROR_OPERATION_NOT_ALLOWED':
        case 'operation-not-allowed':
          errorMessage = 'Server error, please try again later.';
          break;
        default:
          errorMessage = 'Account verification failed.';
          break;
      }
      setError(errorMessage);
    })
  }
 

	// Schema for yup
	const validationSchema = Yup.object().shape({

    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*Email is required"),

    password: Yup.string()
      .min(6, '*Password should be at least 6 characters')
      .max(25, "*Password must be less than 25 characters")
      .required("*Password is required"),
    
  });
  
  return (
  <div>
    {userDetails && (
    <Formik
    initialValues={{ email:userEmail, password:''}}
    validationSchema={validationSchema}
    validateOnMount={true}
    validateOnChange={true}
    validateOnBlur={false}
    onSubmit={(values, {setSubmitting, resetForm}) => {
      // When button submits form and form is in the process of submitting, submit button is disabled
      setSubmitting(true);
      const signUpResponse = updateAccount(values.email, values.password);
      // Simulate submitting to database, shows us values submitted, resets form
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    }}
    >
    {({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    isValid,
    errors,
    }) => (
    <Form className="deleteAccountForm" noValidate onSubmit={handleSubmit}>
    <h4>Delete your account and all associated data</h4>
      <Form.Group controlId="formEmail">
          <Form.Label>Email address:</Form.Label>
          <Form.Control 
            type="email" 
            disabled
            placeholder="Enter your email address"
            name="email"
            onChange={handleChange} 
            onBlur={handleBlur}
            value={values.email}
            className={touched.email && errors.email ? "error" : null}
            isValid={!errors.email}
            isInvalid={errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {touched.email && errors.email ? (
                <div className="error-message">{errors.email}</div>
              ): null}
            </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="updateAccountForm">
        <Form.Label>Password:</Form.Label>
            <Form.Control 
              type="password"
              placeholder="" 
              name="password"
              value={values.password}
              onChange={handleChange} 
              onBlur={handleBlur}
              className={touched.password && errors.password ? "error" : null}
              isValid={touched.password && !errors.password}
              isInvalid={touched.password && errors.password}
              />
            <Form.Control.Feedback type="invalid">
              {touched.password && errors.password ? (
                <div className="error-message">{errors.password}</div>
              ): null}
            </Form.Control.Feedback>
      </Form.Group>

      <MuiButton variant="contained" color="red"  type="submit" disabled={!isValid}>Delete Account</MuiButton>

      <Stack sx={{ width: '100%', marginTop:2 }} spacing={2}>
        {errorMessage ? ( <Alert severity="error">{errorMessage}</Alert> ): null}
        {successMessage ? ( <Alert severity="success">{successMessage}</Alert> ): null}
      </Stack>
    
    </Form>
    )}
  </Formik>
  )}
</div>
  )
};
export default RequestAccountDeletion;

const PresentationContainer = styled.div`
  display: block;
  > hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #49274b;
  }
  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;